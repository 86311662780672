// import React from "react";
// import reactDOMServer from "react-dom/server";
import axios from "axios";

// 判断是否是对应的数据类型
export const isDataType = (obj, type) =>
  Object.prototype.toString.call(obj) === `[object ${type || ""}]`;

/**
 * 查找常量或配置项中的name——应用于表格
 */
export function findNameById(arr = [], id, field = "id") {
  if (!id) return null;
  const target = arr.find(v => Number(v[field]) === Number(id));
  return target && target.name;
}



 const getSessionQuery = {
  query: `query{
      getSession{
        user{
          id
          email
          serverOrg_id
        }
      }
    }`
};

export const loginOut = {
  query: `mutation {
    logout{
     msg
     code
   }
   }
   `
};

export const getSession = (history) => {
  axios.post(`/graphql`, getSessionQuery)
    .then(res => {
      if (
        res.data &&res.data.data&&
        res.data.data.getSession &&
        res.data.data.getSession.user
      ) {
        if(history.location.pathname==='/'||
        history.location.pathname==='/user/login'
        ){
          history.push("/message");
        }else {
          history.push(history.location.pathname);
        }
      }else {
        history.push("/user/login");
      }
    })
};

export const getSessions = (history,message) => {
  axios
    .post(`/graphql`, getSessionQuery)
    .then(res => {
      if (
        res.data &&
        res.data.data.getSession &&
        res.data.data.getSession.user
      ) {
        history.push("/message");
      }
    })
    .catch(err => {
      message.error(err);
    });
};


