/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Button } from "antd";
// import ListItem from "../ListItem";
import "./index.less";

class ListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choosed: false
    };
  }

  clickItem = () => {
    this.setState({
      choosed: true
    });
  };

  render() {
    const { isMore, title, extra, clickExtra,getMore, children, type, table } = this.props;

    // const { choosed } = this.state;

    return (
      <div style={table ? { width: "100%" } : null} className={type !== "message" ? "listCard" : "listCardMessage"}>
        <div className="listCard_title">
          <div className="listCard_titleName">{title}</div>
          <div className="listCard_extra" onClick={clickExtra}>
            {extra && "+ "} {extra}
          </div>
        </div>
        <div className="listCard_line"></div>
        {children}
        {isMore &&
          <Button className="getMore" onClick={getMore}>加载更多</Button>
        }
      </div>
    );
  }
}
export default ListCard;
