import React, { PureComponent } from 'react';
import { Upload, Icon, Modal } from 'antd'

export default class LogoUpload extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.value,
      loading: false,
      previewImage: null,
      previewVisible: false,
    }
  }

  handleChange = file => {
    console.log(file)
  }

  handlePreview = file => {
    console.log(file)
  }

  render() {
    const { imgName='logo', uploadText } = this.props
    const { loading, data } = this.state
    const uploadButton = (
      <div>
        <Icon type={loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">{uploadText || '上传'}</div>
      </div>
    );
    return (
      <Upload
        name={imgName}
        listType="picture-card"
        showUploadList={false}
        action="/api/file/upload"
        onChange={this.handleChange}
        onPreview={this.handlePreview}
        onRemove={this.handleChange}
      >
        {data ? <img src={`/api/file/${data}`} alt="avatar" /> : uploadButton}
      </Upload>
    )
  }
}