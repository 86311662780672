const serverOrg = {
  query: `query{
        serverOrg{ 
          id
          name
          intro
          serverCharacter
          address
          businessLicense
          contact
          contactNumber
          contactEmail
        }
      }`
};

const mutation = {
  query: `mutation($input:UpdateServerOrg){ 
        updateServerOrg(input:$input){
          code
          msg
        } 
      }
      `
};

const updatePassword = {
  query: `mutation($oldPassword:String!,$password:String!){ 
    updatePassword(oldPassword:$oldPassword,password:$password){
          code
          msg
        } 
      }
      `
};

// 版本信息
export const getversion = {
  query: `query{
    version{
      id
      issueDate
      detail
      versionNum
      }
    }`
};


const queryList = {
  serverOrg,
  mutation,
  getversion,
  updatePassword
};

export default queryList;
