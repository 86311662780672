import React from "react";
import { Form } from "antd";
import { setFormInitialValue, setFormInputComp } from "./showUnit";
import { formItemLayout } from "./unit";

const FormItem = Form.Item;

const ShowFormEdit = props => {
  const {
    data,
    keyList,
    fieldProps,
    form,
    handleFormChange,
    formLayout
  } = props;
  const { getFieldDecorator } = form;

  const handleChange = (e, formId) => {
    if (handleFormChange) {
      handleFormChange(e, formId);
    }
  };
  const formList = keyList.map(ele => {
    const key = ele;
    const prop = fieldProps[key];
    const formKey = prop.formField;

    const rules = prop.rules || [];
    const value =
      setFormInitialValue(data && data[prop.formField], prop) || null;
    const layout = prop.formLayout || formLayout || formItemLayout;
    const formItemProps = prop.formItemProps || {};
    
    return (
      <div key={ele}>
        <FormItem label={prop.label} {...layout} {...formItemProps}>
          {getFieldDecorator(formKey, {
            initialValue: value,
            rules: [...rules]
          })(setFormInputComp(prop, e => handleChange(e, prop.value)))}
        </FormItem>
      </div>
    );
  });

  return <div id="add-modal">{formList}</div>;
};

export default ShowFormEdit;
