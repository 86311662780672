
export const serviceColumns = [
    {
      title: "推荐服务名称",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "服务类别",
      key: "type",
      dataIndex: "type"
    },
    {
      title: "服务项目",
      key: "item",
      dataIndex: "item"
    },
    {
      title: "服务优势",
      key: "advantage",
      dataIndex: "advantage"
    }
  ];

  const tableListConfig = {
    serviceColumns
  };
  
  export default tableListConfig;