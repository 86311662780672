const fieldProps = {
  service_id: {
    dataFormartType: "normalSelect",
    inputType: "Select",
    formField: "service_id",
    label: "关联服务",
    chooseList: "query.parkList",
    chooseId: "id",
    dbType: "string",
    rules: [{ required: true, message: "此项为必填项" }]
  },
  img: {
    dataFormartType: "normalInput",
    formField: "img",
    label: "企业标志",
    inputType: "PicUpload",
    dbType: "string",
    compProps: { length: 1, imgName: "img" }
  },
  companyName: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "companyName",
    label: "合作企业",
    dbType: "string",
    rules: [
      { required: true, message: "此项为必填项" },
      {
        max: 30,
        message: "长度限制30字以内"
      }
    ]
  },
  prjName: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "prjName",
    label: "服务项目",
    dbType: "string",
    rules: [
      {
        max: 30,
        message: "长度限制30字以内"
      }
    ]
  },
  details: {
    dataFormartType: "normalInput",
    inputType: "TextArea",
    formField: "details",
    label: "案例详情",
    dbType: "string",
    compProps: { rows: 4, style: { minHeight: "120px" } },
    rules: [
      {
        max: 300,
        message: "长度限制300字以内"
      }
    ]
  },
  advantage: {
    dataFormartType: "normalInput",
    inputType: "TextArea",
    formField: "advantage",
    label: "服务优势",
    dbType: "string",
    compProps: { rows: 4, style: { minHeight: "120px" } },
    rules: [
      {
        max: 30,
        message: "长度限制30字以内"
      }
    ]
  }
};

const caseParkFieldProps = { fieldProps };
export default caseParkFieldProps;
