// 园区列表
export const getServerParksQuery = {
    query: `query($input: ServerParkQuery){
        serverParks(input:$input){
        count
        rows{
          id
      serverOrg_id
      park_id
      park {
        id
        name
        counterpart
        counterpartNum
      }
        }
    }
  }`
};

// 取消园区关联
export const deleteServerPark = {
    query: `mutation($id:  ID!){
        deleteServerPark(id:$id){
      code
      msg
    }
  }`
};

// 新增关联
export const addServerPark = {
    query: `mutation($input:AddServerPark!){
        addServerPark(input:$input){
      code
      msg
    }
  }`
};

// 园区详情
export const getserverParkQuery = {
    query: `query($id:  ID!){
  serverPark(id:$id){
    id
    serverOrg_id
    park_id
    park{
      id
      name
      counterpart
      counterpartNum
    }
  }
}`
};

// 所有园区
export const getparksQuery = {
    query: `query{
        parks{
          id
          name
          counterpart
          counterpartNum
        }
      }`
}

// 新增推荐服务
export const addParkProject = {
    query: `
    mutation($input: AddParkProject){
        addParkProject(input:$input){
          code
          msg
        }
      }`
};

// 取消推荐服务
export const deleteParkProject= {
    query: `
    mutation($id: ID!){
        deleteParkProject(id:$id){
          code
          msg
        }
      }`
};