/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Button, Form, Input, Row, Col, message } from "antd";
import FileUpload from "../../components/FileUpload/FileUpload";
import axios from "axios";
import { getSessions } from "../../utils/utils";
import "./index.less";

const FormItemLayout = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 19
  }
};

let maxTime = 60

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      btnText: '发送验证码',
      btnBool: false,
      disableds:false
    }
  }

  // 去登录
  onSubmit = () => {
    const {
      form: { validateFieldsAndScroll },
      history
    } = this.props;
    validateFieldsAndScroll((err, value) => {

      if (!err) {
        delete value.password1;
        axios
          .post(`/api/v1/user/register`, value)
          .then(res => {
            if (res.data.code === 200) {
              message.success("注册成功！");
              axios
                .post(`/api/v1/user/login`, value)
                .then(res => {
                  if (res.data.code === 200) {
                    getSessions(history, message);
                  } else {
                    message.error(res.data.msg);
                  }
                })
                .catch(err => {
                  message.error(err);
                });
            } else {
              message.error(res.data.msg);
            }
          })
          .catch(err => {
            message.error(err);
          });
      }
    });
  };

  // 获取验证码
  alterCaptcha = () => {
    const { form } = this.props;
    const value = form.getFieldValue("email");
    
    if (value) {
      this.setState({
        disableds: true
      })
      maxTime = 60
      axios
        .get(
          `/api/v1/user/captcha?email=${value}&action=register&devEmail=${value}`
        )
        .then(res => {
          if (res && res.data && res.data.code === 200) {
            this.setState({
              disableds: false
            })
            this.timer = setInterval(() => {
              if (maxTime > 0) {
                --maxTime
                this.setState({
                  btnText: '重新获取' + maxTime,
                  btnBool: true
                })
              }
              else {
                this.setState({
                  btnText: '发送验证码',
                  btnBool: false
                })
                clearInterval(this.timer)
              }
            }, 1000)
            message.success(res.data.msg);
          } else {
            this.setState({
              disableds: false
            })
            message.error(res.data.msg);
          }
        })
      // .catch(err => {
      //   message.error(err);
      // });
    } else {
      message.error("邮箱号不能为空！");
    }
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(["password1"], { force: true });
    }
    if (value) {
      if (!/^[a-zA-Z0-9]+$/.test(value)) {
        return callback("输入英文或者数字组成密码");
      }
      if (value.length < 6 || value.length > 16) {
        return callback("输入6-16位密码");
      }
    }
    return callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次密码输入不一致");
    } else {
      callback();
    }
  };

  toLogin = () => {
    const { history } = this.props;
    history.push("/user/login");
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { btnBool, btnText , disableds } = this.state;
    return (
      <div className="registerForms">
        <Form
          layout="horizontal"
          // onSubmit={this.onSubmit}
          labelAlign="left"
          colon={false}
          {...FormItemLayout}
        >
          <Form.Item label="邮箱">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "请填写邮箱" },
                { type: 'email', message: "请输入正确的邮箱格式" }
              ]
            })(<Input placeholder="请填写邮箱" allowClear />)}
          </Form.Item>

          <Form.Item label="验证码">
            <Row gutter={8} style={{ overflow: "hidden" }}>
              <Col span={14}>
                {getFieldDecorator("mailCode", {
                  rules: [
                    { required: true, message: "请输入验证码" },
                    {
                      pattern: /^[0-9a-zA-Z]+$/,
                      message: "请输入英文字母或数字",
                    },
                  ]
                })(<Input maxLength={6} autoComplete="off" allowClear />)}
              </Col>
              <Col span={10}>
                <Button
                  style={{ backgroundColor: "#3C6CA2", color: "#fff" }}
                  onClick={this.alterCaptcha.bind(this)}
                  disabled={btnBool}
                  loading={disableds}
                >
                  {btnText}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="密码">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "请输入密码" },
                {
                  validator: this.validateToNextPassword
                }
              ]
            })(<Input type="password" placeholder="请输入密码" allowClear />)}
          </Form.Item>
          <Form.Item label="重复密码">
            {getFieldDecorator("password1", {
              rules: [
                { required: true, message: "请输入密码" },
                {
                  validator: this.compareToFirstPassword
                }
              ]
            })(<Input type="password" onBlur={this.handleConfirmBlur} placeholder="请输入密码" allowClear />)}
          </Form.Item>
          <Form.Item label="公司名称">
            {getFieldDecorator("orgName", {
              rules: [
                { required: true, message: "请输入公司名称" },
                {
                  max: 30,
                  message: "长度限制30字以内"
                }
              ]
            })(<Input placeholder="请输入公司名称" />)}
          </Form.Item>
          <Form.Item label="营业执照">
            {getFieldDecorator("businessLicense", {
              rules: [{ required: true, message: "请上传营业执照" }],
            })(<FileUpload maxLength={1} />)}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={this.onSubmit}
            // loading={loading}
            style={{ width: "100%" }}
          >
            注册
          </Button>
        </Form>
        <div className="toLogin">
          <div>
            已有账号
            <span
              style={{ cursor: "pointer", color: "#268CFF" }}
              onClick={this.toLogin}
            >
              登录
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const Registers = Form.create({})(Register);
export default Registers;
