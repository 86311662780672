/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import ListCard from "../../components/ListCard";
import ListItem from "../../components/ListItem";
import FieldShowCard from "../../components/ShowFieldComp/FieldShowCard";
import { showKeyList } from "./unit";
import { message } from "antd";
import { getSession } from "../../utils/utils";
// import { findNameById } from "../../utils/utils";
import axios from "axios";
import moment from "moment";
// import { formartFieldProps } from "../../components/ShowFieldComp/unit";
import messageFieldProps from "../../utils/fieldsConfig/message";
import queryList from "../../graphql/message";
import "./index.less";

const { fieldProps } = messageFieldProps;

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: 0,
      detail: null,
      page: 1,
      pageSize: 8,
      index: 1,
      isClick: true,
      list: []
    };
  }

  componentDidMount() {
    const { history }=this.props;
    this.getList();
    getSession(history);
  }

  getList = param => {
    const { page, pageSize } = this.state;
    const obj = {
      query: queryList.message.query,
      variables: {
        input: {
          page,
          pageSize: param || pageSize
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res.data && res.data.data && res.data.data.applications) {
        const count = res.data.data.applications.count;
        const data = res.data.data.applications.rows;
        this.setState({
          list: data,
          count
        });

        if (data.length !== 0) {
          this.setState({
            itemId: data[0] && data[0].id
          });

          this.getDetail(data[0] && data[0].id);
        }
      }
    });
  };

  getDetail = id => {
    if (!id) {
      return;
    }

    const obj = {
      query: queryList.messageDetail.query,
      variables: { id: Number(id) }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (
        res &&
        res.data.data &&
        res.data.data.application &&
        res.data.data.application
      ) {
        this.setState({
          detail: res.data.data.application
        });
      }
    });
  };

  updateList = index => {
    const { page, pageSize } = this.state;
    const obj = {
      query: queryList.message.query,
      variables: {
        input: {
          page,
          pageSize
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res.data && res.data.data && res.data.data.applications) {
        const count = res.data.data.applications.count;
        const data = res.data.data.applications.rows;
        this.setState({
          list: data,
          count
        });

        if (data.length !== 0) {
          const newIndex = index - 1 !== -1 ? index - 1 : 0;
          this.setState({
            itemId: data[newIndex] && data[newIndex].id
          });
          this.getDetail(data[newIndex] && data[newIndex].id);
        } else {
          this.setState({
            detail: {}
          });
        }
      }
    });
  };

  clickItem = id => {
    const { list } = this.state;

    list.forEach(e => {
      if (e.id - 0 === id - 0) {
        e.isRead = true;
      }
    });
    this.setState({
      itemId: id
    });
    this.getDetail(id);
  };

  clickData = data => {
    if (!data || (data && data.length === 0)) return;
    this.del(Number(data.id));
    // const { confirm } = Modal;
    // confirm({
    //   title: "确认删除当前服务吗？",
    //   okText: "删除",
    //   okType: "danger",
    //   cancelText: "取消",
    //   onOk: () => {
    //     this.del(Number(data.id));
    //   },
    //   onCancel() {}
    // });
  };

  del = id => {
    const { list, isClick } = this.state;
    if (!isClick) {
      return;
    }
    this.setState({
      isClick: false
    });
    const index = list.findIndex(e => e.id - 0 === id);
    const obj = {
      query: queryList.mutation.query,
      variables: { id: id }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (
        res.data &&
        res.data.data &&
        res.data.data.deleteApplication.code === 200
      ) {
        message.success("更新成功！");
        this.updateList(index);
      }
    });

    setTimeout(() => this.setState({ isClick: true }), 1000);
  };

  setListData = (data = []) => {
    const newData = [];
    data.forEach(e => {
      const item = { ...e };
      item.name = item.park && item.park.name;
      item.examine =
        item.auditingStatus !== null || item.auditingStatus !== undefined
          ? item.auditingStatus
            ? "已同意"
            : "被驳回"
          : "";
      item.auditingTime = item.auditingTime
        ? moment(item.auditingTime).format("YYYY-MM-DD")
        : "";
      newData.push(item);
    });
    return newData;
  };

  getMore = () => {
    const { pageSize, index } = this.state;
    this.getList((index + 1) * pageSize);
    this.setState({
      index: index + 1,
      pageSize: (index + 1) * pageSize
    });
  };

  render() {
    const { itemId, detail, list ,pageSize,count} = this.state;
    const showKey = showKeyList.showKey;
    // let fieldProp = {};
    // const listObj = { query: { examineList: examineList } };
    // showKey.forEach(e => {
    //   fieldProp[e] = formartFieldProps(fieldProps[e], listObj);
    // });
    if (detail) {
      detail.examineNew =
        detail.auditingStatus !== null || detail.auditingStatus !== undefined
          ? detail.auditingStatus
            ? "已同意"
            : "被驳回"
          : "";
      detail.parkName = detail.park ? detail.park.name : "";
    }

    const renderList = this.setListData(list);

    // const when = ["置顶信息", "昨天", "周一", "上周"];
    const isMore = count > pageSize ? true : false;
   
    return (
      <div className="message">
        <div className="messageleftList">
          <ListCard
            type="message"
            title="消息"
            clickExtra={this.clickExtra}
            isMore={isMore}
            getMore={this.getMore}
            >
            {renderList.map((item, index) => {
              return (
                <div key={index.toString()}>
                  <div
                    key={index.toString()}
                    onClick={() => this.clickItem(item.id)}
                  >
                    <ListItem
                      data={item}
                      type="message"
                      choosed={item.id === itemId && true}
                    />
                  </div>
                </div>
              );
            })}
          </ListCard>
        </div>
        <div className="rightInfo">
          {list && list.length !== 0 && (
            <div className="rightCard">
              <FieldShowCard
                cardExtre={["删除"]}
                title={detail && `${detail.parkName}服务推荐申请`}
                keyList={showKey}
                fieldProps={fieldProps}
                data={detail && detail}
                clickData={this.clickData}
              />
            </div>
          )}
          {list && list.length !== 0 && (
            <div className="thirdCard">
              <div className="messageInfo">
                申请原文：
                <div className="message_word">{detail && detail.remark}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Message;
