import React from "react";
import { Row, Col } from "antd";
import { handleShowValue } from "./showUnit";
import "./index.less";

const ShowFieldValue = props => {
  const {
    keyList = [],
    fieldProps,
    data = {},
    clickData,
    loading = false,
    colGrid,
    rowProps
  } = props;
  const rowLayout = rowProps || {
    type: "flex",
    gutter: 24
  };
  const colLayout = colGrid || { xs: 24, sm: 24, md: 24, lg: 24 };
  const showList = keyList.map(ele => {
    const key = ele;
    const prop = fieldProps[key];
    const value = data && data[prop.formField];
    const label = prop.label || "";

    return (
      <Col
        className="mb8"
        key={ele}
        {...colLayout}
        style={{ marginBottom: 12 }}
      >
        <div className="showitem">
          {prop.label && <div className="detail-label">{label}：</div>}
          <div
            className={
              key === "examineNew" && value
                ? value === "已同意"
                  ? "examine1"
                  : "examine2"
                : "detail-content"
            }
          >
            {handleShowValue(value, prop, loading, clickData)}
          </div>
        </div>
      </Col>
    );
  });

  return (
    <div className="showFieldValue">
      <Row {...rowLayout}>{showList}</Row>
    </div>
  );
};

export default ShowFieldValue;
