/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { message } from "antd";
import ListCard from "../../components/ListCard";
import ListItem from "../../components/ListItem";
import EditFormModal from "../../components/ShowFieldComp/EditFormModal";
import FieldShowCard from "../../components/ShowFieldComp/FieldShowCard";
import { showKeyList } from "./unit";
import axios from "axios";
import {
  getserverCasesQuery,
  getserverCase,
  addServerCase,
  getAllProjectsQuery,
  updateServerCase,
  deleteServerCase,
  updateCaseXC
} from "../../graphql/case";
import { getSession } from "../../utils/utils";
import { formartFieldProps } from "../../components/ShowFieldComp/unit";
import caseParkFieldProps from "../../utils/fieldsConfig/case";
import "./index.less";

const { fieldProps } = caseParkFieldProps;

class Case extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 8,
      index: 1,
      parkList: [],
      itemId: 0,
      editVisible: false,
      data: [],
      count: 0,
      detail: {},
      detailvisible: false,
      editTitle: "",
      isClick: true,
      editData: {} // 编辑框详情
    };
  }

  componentDidMount() {
    const { history }=this.props;
    this.getList();
    getSession(history);
    this.getAllProjects();
  }

  // 获取案例展示列表
  getList = (index) => {
    const { page, pageSize ,itemId } = this.state;
    const obj = {
      query: getserverCasesQuery.query,
      variables: {
        input: {
          page,
          pageSize: index || pageSize
        }
      }
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (res && res.data.data && res.data.data.serverCases) {
          const counts = res.data.data.serverCases.count;
          const datas = res.data.data.serverCases.rows;
          this.setState({
            data: datas,
            count: counts
          });
          if (datas.length !== 0) {
            if (itemId) {
              this.getDetail(itemId)
            } else {
              this.getDetail(datas[0].id)
            }
          }
        }else {
          message.error(res.data.data.serverCases.msg);
        }
      })
  };

  updateList = index => {
    const { page, pageSize } = this.state;
    const obj = {
      query: getserverCasesQuery.query,
      variables: {
        input: {
          page,
          pageSize
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res && res.data.data && res.data.data.serverCases) {
        const counts = res.data.data.serverCases.count;
        const datas = res.data.data.serverCases.rows;
        this.setState({
          data: datas,
          count: counts
        });
        if (datas.length !== 0) {
          const newIndex = index - 1 !== -1 ? index - 1 : 0;
          this.setState({
            itemId: datas[newIndex] && datas[newIndex].id
          });
          this.getDetail(datas[newIndex] && datas[newIndex].id);
        } else {
          this.setState({
            detail: {}
          });
        }
      }else {
        message.error(res.data.data.serverCases.msg);
      }
    });
  };

  // 获取案例详情
  getDetail = (id) => {
    const obj = {
      query: getserverCase.query,
      variables: {id}
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (res && res.data.data && res.data.data.serverCase) {
          this.setState({
            detail:res.data.data.serverCase,
            itemId: id
          });
        }else {
          message.error(res.data.data.serverCase.msg);
        }
      })
  };


  // 获取所有服务
  getAllProjects = () => {
    const obj = {
      query: getAllProjectsQuery.query
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (res.data && res.data.data && res.data.data.getAllProjects) {
          this.setState({
            parkList: res.data.data.getAllProjects
          });
        }else {
          message.error(res.data.data.getAllProjects.msg);
        }
      })
  };

  clickExtra = () => {
    this.setState({
      editVisible: true,
      editTitle: "新增案例情况"
    });
  };

  clickItem = (id,index) => {
    this.getDetail(id,index)
    this.setState({
      itemId: id
    });
  };

  save = value => {
    const { editTitle, detail , isClick } = this.state;
    if (!isClick) {
      return;
    }
    this.setState({
      isClick: false
    });
    if (editTitle === "新增案例情况") {
      const obj = {
        query: addServerCase.query,
        variables: {
          input: { ...value }
        }
      };
      axios
        .post(`/graphql`, obj)
        .then(res => {
          if (res.data && res.data.data.addServerCase.code === 200) {
            message.success("新增成功！");
            this.getList();
            this.setState({
              editVisible: false
            });
          }else {
            message.error(res.data.data.addServerCase.msg);
          }
        })
    } else if (editTitle === "修改案例情况") {
      const obj = {
        query: updateServerCase.query,
        variables: {
          input: { ...value, id: detail.id }
        }
      };
      axios
        .post(`/graphql`, obj)
        .then(res => {
          if (res.data && res.data.data.updateServerCase.code === 200) {
            message.success("修改成功！");
            this.getList();
            this.setState({
              editVisible: false
            });
          }else {
            message.error(res.data.data.updateServerCase.msg);
          }
        })
    }
    setTimeout(() => this.setState({ isClick: true }), 1000);
  };

  cancel = () => {
    this.setState({
      editVisible: false,
      detailvisible: false,
      allId: []
    });
  };

  // 删除
  delete = id => {
    const { data }=this.state
   
    const index = data.findIndex(e => e.id - 0 === Number(id));
   
    const obj = {
      query: deleteServerCase.query,
      variables: { id }
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (res.data && res.data.data.deleteServerCase.code === 200) {
          message.success("删除成功！");
          this.updateList(index);
        }else {
          message.error(res.data.data.deleteServerCase.msg);
        }
      })
  };

  // 手动更新
  getReflesh=(id)=>{
    if (!id) {
      return;
    }
    const obj = {
      query: updateCaseXC.query,
      variables: { id: Number(id) }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (
        res &&
        res.data.data &&
        res.data.data.updateCaseXC &&
        res.data.data.updateCaseXC.code===200
      ) {
        message.success("手动更新成功！")
      }else{
        message.error(res.data.data.updateCaseXC.msg)
      }
    });
}

  clickData = (data, type) => {
    const { parkList , detail }=this.state
    if (type === "修改") {
      const parkIds=[]
      parkList.forEach(ee=>{
        parkIds.push(ee.id)
      })
      if(parkIds.indexOf(detail.service_id)===-1){
        detail.service_id=null;
        this.setState({
          detail
        })
      }
      this.setState({
        editTitle: "修改案例情况",
        editVisible: true,
        editData: data
      });
    }
    if (type === "删除") {
      this.delete(data.id);
    }
    if (type === "手动更新") {
      this.getReflesh(Number(data.id))
    }
  };

  // 加载更多
  getMore = () => {
    const { pageSize, index } = this.state;
    this.getList((index + 1) * pageSize);
    this.setState({
      index: index + 1,
      pageSize: (index + 1) * pageSize
    });
  };

  render() {
    const {
      data,
      itemId,
      editVisible,
      detail,
      editData,
      editTitle,
      parkList,
      count,
      pageSize
    } = this.state;
    const editList = showKeyList.editKey;
    let fieldProp = {};
    const listObj = { query: { parkList: parkList } };
    editList.forEach(e => {
      fieldProp[e] = formartFieldProps(fieldProps[e], listObj);
    });

    const isMore = count > pageSize ? true : false;
    return (
      <div className="case">
        <div className="leftList">
          <ListCard
            title="案例展示列表"
            extra="新增案例"
            clickExtra={this.clickExtra}
            isMore={isMore}
            getMore={this.getMore}
          >
            {data.map((item, index) => {
              item.name = item.companyName;
              // console.log(item,itemId)
              return (
                <div
                  key={index.toString()}
                  onClick={() => this.clickItem(item.id,index)}
                >
                  <ListItem
                    data={item}
                    type="case"
                    choosed={item.id === itemId && true}
                  />
                </div>
              );
            })}
          </ListCard>
        </div>
        <div className="rightInfo">
          {data && data.length !== 0 &&
            <div className="rightCard">
              <FieldShowCard
                cardExtre={["手动更新","修改", "删除"]}
                title="成功案例"
                image={detail && detail.img}
                keyList={showKeyList.showKey}
                fieldProps={fieldProps}
                data={detail && detail}
                clickData={this.clickData}
              />
            </div>
          }
        </div>
        <EditFormModal
          visible={editVisible}
          title={editTitle}
          onCancel={this.cancel}
          save={this.save}
          editList={editList}
          fieldProps={fieldProp}
          data={editTitle === "修改案例情况" ? editData : null}
          width="600px"
        />
      </div>
    );
  }
}
export default Case;
