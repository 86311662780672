/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import queryList from "../../graphql/system";
import { message } from "antd";
import moment from "moment";
import axios from "axios";
import { getSession } from "../../utils/utils";
import TitleCard from "../../components/TitleCard/index";
import "./index.less";

class Version extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {}
    };
  }

  componentDidMount() {
    const { history }=this.props;
    getSession(history);
    this.getversion();
  }

  // 获取版本信息
  getversion = () => {
    const obj = {
      query: queryList.getversion.query
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (res && res.data && res.data.data.version) {
          this.setState({
            detail: res.data.data.version
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  render() {
    const { detail } = this.state;

    return (
      <div className="system_wraper">
        <div className="info_sys">
          <div style={{ display: "inline-flex" }}>
            <div className="sys_one">{""}</div>
            <div>
              系统配置 / <span className="sys_title">版本信息</span>
            </div>
          </div>
          <div className="sys_card">
            <div className="sys_cardTitle">
              <TitleCard title="版本信息" />
              <div className="sysCardHeards">
                <div className="sys_one">{""}</div>
                <div className="basicSituation">
                  {detail.versionNum}版本更新内容
                </div>
              </div>
              <div className="content_version">
                <div className="time">
                  版本发布时间：
                  <span className="version">{detail.issueDate&&moment(detail.issueDate).format("YYYY-MM-DD HH:MM")}</span>
                </div>
                <div className="content">
                  版本更新内容：<span className="version">{detail.detail}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Version;
