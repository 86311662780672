import React from "react";
import { Card, Button, Popconfirm } from "antd";
import ShowFieldValue from "./ShowFieldValue";
import styles from "./index.less";

const FieldShowCard = props => {
  const {
    title,
    keyList = [],
    fieldProps = {},
    data = [],
    clickData = () => {},
    cardExtre,
    image
  } = props;

  const text = "是否确认删除?";
  const cardExtra =
    cardExtre && cardExtre instanceof Array ? (
      cardExtre.map((item, index) => {
        if (item === "修改" || item === "手动更新") {
          return (
            <span key={index.toString()}>
              <Button onClick={() => clickData(data, item)} type="primary">
                {item}
              </Button>
              <span style={{ marginRight: 10 }} />
            </span>
          );
        } else {
          return (
            <span key={index.toString()}>
              {item === "删除" ? (
                <Popconfirm
                  title={text}
                  onConfirm={() => clickData(data, item)}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button>{item}</Button>
                </Popconfirm>
              ) : (
                <Button onClick={() => clickData(data, item)}>{item}</Button>
              )}
              <span style={{ marginRight: 10 }} />
            </span>
          );
        }
      })
    ) : (
      <Button onClick={() => clickData(data)}>{cardExtre}</Button>
    );
  return (
    <div className={styles.fieldShowCard}>
      <Card title={title} extra={cardExtra}>
        {image && (
          <div style={{ float: "left", width: 120, height: 120 }}>
            <img
              src={`https://ybsatest.yuanbaoai.com/api/v1/file/${image}`}
              alt="example"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        )}
        <ShowFieldValue
          keyList={keyList}
          fieldProps={fieldProps}
          // clickData={clickData}
          data={data}
          image={image}
        />
      </Card>
    </div>
  );
};

export default FieldShowCard;
