export const textC = [
  { id: 1, name: "cece" },
  { id: 2, name: "测试第二条" }
];

// 服务类别
export const serviceType=[
  {
    id:1,
    name:'知识产权',
  },
  {
    id:2,
    name:'知识产权1',
  },
  {
    id:3,
    name:'知识产权2',
  },
] 

// 机构性质
export const serverCharacter = [
  {
    id: 1,
    name: "全额拨款事业",
  },
  {
    id: 2,
    name: "差额拨款事业",
  },
  {
    id: 3,
    name: "自收自支事业",
  },
  {
    id: 4,
    name: "国营",
  },
  {
    id: 5,
    name: "民营",
  },
  {
    id: 6,
    name: "民办非企业",
  },
  {
    id: 7,
    name: "混合所有制",
  },
];

export const constant = {
  textC,
  serviceType,
  serverCharacter
};
