/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { message, Popconfirm } from "antd";
import axios from "axios";
import { loginOut } from '../../utils/utils'
// import ContentMain from "./ContentMain";
// import { Redirect, Route, Switch } from "react-router-dom";
// import Message from "../pages/Message";
// import ServerPark from "../pages/ServerPark";
// import Project from "../pages/Project";
// import ServerOrgInfo from "../pages/System/ServerOrgInfo";
// import Password from "../pages/System/Password";
// import Version from "../pages/System/Version";
// import Case from "../pages/Case";
import "./index.less";

// const { Content } = Layout;

const list = [
  "消息",
  "服务园区管理",
  "服务项目管理",
  "案例展示管理",
  "系统配置",
  "退出登录"
];

const url = [
  "/message",
  "/serverPark",
  "/project",
  "/case",
  "/system/serverOrgInfo",
  "/user"
];

const secondUrl = [
  "/system/serverOrgInfo",
  "/system/password",
  "/system/version"
];

class Heard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      indexClick: null,
      showMenu: false
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (history.location.pathname !== "/") {
      history.push(history.location.pathname);
    } else {
      history.push(url[0]);
    }
    if (url.indexOf(history.location.pathname) !== -1) {
      this.setState({
        current: url.indexOf(history.location.pathname)
      });
    }
  }

  loginOut = () => {
    const { history } = this.props;
    axios.post(`/graphql`, loginOut)
      .then(res => {
        if (res && res.data.data.logout.code === 200) {
          history.push('/user/login');
          message.success("您已退出登录！")
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  handleClick = index => {
    const { history } = this.props;
    if (index !== 4) {
      if (index === 5) {
        // 退出登录
        this.loginOut()
      } else {
        history.push(url[index]);
      }
      this.setState({
        indexClick: null,
        current: index
        // showMenu: index === 4 && true
      });
    }
  };

  changeonMouseOver = index => {
    this.setState({
      showMenu: index === 4 && true
    });
  };

  changeonMouseOut = index => {
    this.setState({
      showMenu: false
    });
  };

  systemClick = index => {
    this.setState({
      indexClick: index,
      current: 4
    });
    const { history } = this.props;
    history.push(secondUrl[index]);
  };

  render() {
    const { details } = this.props
    const { current, showMenu, indexClick } = this.state;
    const systemList = ["服务机构信息", "密码管理", "版本信息"];

    const top = list.map((e, index) => {
      return (
        <span key={index.toString()}>
          {index === 5 ? (
            <Popconfirm
              title="是否退出登录？"
              onConfirm={() => this.handleClick(index)}
              okText="确定"
              cancelText="取消"
            >
              <span
                className="init"
                onMouseOver={() => this.changeonMouseOver(index)}
                style={current === index ? { backgroundColor: "#006ee9" } : {}}
              >
                {e}
              </span>
            </Popconfirm>
          ) :
            <span
              className="init"
              // className={index === 4 ? "system" : "init"}
              onClick={() => this.handleClick(index)}
              onMouseOver={() => this.changeonMouseOver(index)}
              style={current === index ? { backgroundColor: "#006ee9" } : {}}
            >
              {e}
              {index === 4 && <span className="corner" />}
              {index === 4 && (
                <div
                  className={showMenu ? "showMenu" : "hidden"}
                  onMouseLeave={() => this.changeonMouseOut()}
                >
                  {systemList.map((ee, indexs) => {
                    return (
                      <div
                        className="systeminit"
                        style={indexClick === indexs ? { color: "#268cff" } : {}}
                        onClick={() => this.systemClick(indexs)}
                        key={indexs.toString()}
                      >
                        {ee}
                      </div>
                    );
                  })}
                </div>
              )}
            </span>
          }
        </span>

      );
    });

    return (
      <div className="basicLayout">
        <div className="header">
          <div className="logo">{details && details.name}</div>

          <div className="listMenu">{top}</div>
        </div>
      </div>
    );
  }
}
export default Heard;
