/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Button, Form, message } from "antd";
import { showKeyList } from "./unit";
import ShowFormEdit from "../../components/ShowFieldComp/ShowFormEdit";
import serverOrgInfoFieldProps from "../../utils/fieldsConfig/serverOrgInfo";
import TitleCard from "../../components/TitleCard/index";
import { formartFieldProps } from "../../components/ShowFieldComp/unit";
import queryList from "../../graphql/system";
import { serverCharacter } from "../../utils/constant";
import { getSession } from "../../utils/utils";
import axios from "axios";
import "./index.less";

const { fieldProps } = serverOrgInfoFieldProps;

// @Form.create()
class ServerOrgInfos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isClick: false
    };
  }

  componentDidMount() {
    const { history }=this.props;
    this.getList();
    getSession(history);
  }

  getList = () => {
    const obj = {
      query: queryList.serverOrg.query
    };
    axios.post(`/graphql`, obj).then(res => {
      // console.log(res);
      if (res.data && res.data.data && res.data.data.serverOrg) {
        this.setState({
          data: res.data.data.serverOrg
        });
      }
    });
  };

  handleFormChange=()=>{
    this.setState({
      isClick: true
    })
  }

  save = () => {
    const { isClick } = this.state;
    if (!isClick) {
      return;
    }
    
    const {
      form: { validateFieldsAndScroll }
    } = this.props;
    validateFieldsAndScroll((err, value) => {
      if (!err) {
        const obj = {
          query: queryList.mutation.query,
          variables: {
            input: {
              ...value
            }
          }
        };
        axios.post(`/graphql`, obj).then(res => {
          if (
            res.data &&
            res.data.data &&
            res.data.data.updateServerOrg.code === 200
          ) {
            message.success("更新成功！");
            this.setState({
              isClick: false
            });
            this.getList();
          }else{
            message.error(res.data.data.updateServerOrg.msg)
          }
        });
      }
    });

    // setTimeout(() => this.setState({ isClick: true }), 1000);
  };

  render() {
    const { form } = this.props;
    const { data, isClick } = this.state;
    const editList = showKeyList.editKey;
    let fieldProp = {};
    const listObj = { query: { typeList: serverCharacter } };
    editList.forEach(e => {
      fieldProp[e] = formartFieldProps(fieldProps[e], listObj);
    });

    return (
      <div className="system_wraper">
        <div className="info_sys">
          <div style={{ display: "inline-flex" }}>
            <div className="sys_one">{""}</div>
            <div>
              系统配置 / <span className="sys_title">服务机构信息</span>
            </div>
          </div>
          <div className="sys_card">
            <div className="sys_cardTitle">
              <TitleCard title="服务机构信息" />
            </div>
            <div className="sysCardHeard">
              <div className="sys_one">{""}</div>
              <div className="basicSituation">基本情况</div>
            </div>
            <Form>
              <ShowFormEdit
                form={form}
                data={data ? data : {}}
                keyList={showKeyList.editKey}
                fieldProps={fieldProp}
                handleFormChange={this.handleFormChange}
              />
            </Form>
            <div style={{ textAlign: "center", marginBottom: 20 }}>
              <Button type="primary" onClick={this.save} disabled={!isClick}>
                保存
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default ServerOrgInfo;
const ServerOrgInfo = Form.create({})(ServerOrgInfos);
export default ServerOrgInfo;
