const fieldProps = {
  parkName: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "parkName",
    label: "申请园区",
    dbType: "string"
  },
  // examine: {
  //   dataFormartType: "normalInput",
  //   inputType: "Input",
  //   formField: "examine",
  //   label: "审批结果",
  //   // chooseList: "query.examineList",
  //   // chooseId: "id",
  //   dbType: "string"
  // },
  examineNew: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "examineNew",
    label: "审批结果",
    // chooseList: "query.examineList",
    // chooseId: "id",
    dbType: "string"
  },
  comment: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "comment",
    label: "申请意见",
    dbType: "string"
  }
};

const messageFieldProps = { fieldProps };
export default messageFieldProps;
