const fieldProps = {
  park_id: {
    dataFormartType: "normalSelect",
    inputType: "Select",
    formField: "park_id",
    label: "选择园区",
    chooseList: "query.parkList",
    chooseId: "id",
    dbType: "number"
  },

  remark: {
    dataFormartType: "normalInput",
    inputType: "TextArea",
    formField: "remark",
    label: "说明",
    dbType: "string",
    compProps: { rows: 4, style: { minHeight: "120px" } },
    rules: [
      // { required: true, message: "此项为必填项" },
      // {
      //   pattern: /^[\w\u4e00-\u9fa5()（）]+$/,
      //   message: '请输入中英文字符'
      // },
      {
        max: 200,
        message: "长度限制200字以内"
      }
    ]
  },
  counterpart: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "counterpart",
    label: "联系人",
    dbType: "string"
  },
  counterpartNum: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "counterpartNum",
    label: "联系电话",
    dbType: "string"
  },

  name: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "name",
    label: "服务名称",
    dbType: "string"
  },
  type: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "type",
    label: "服务类别",
    dbType: "string"
  },
  item: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "item",
    label: "服务项目",
    dbType: "string"
  },
  advantage: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "advantage",
    label: "服务优势",
    dbType: "string"
  }
};

const serverParkFieldProps = { fieldProps };
export default serverParkFieldProps;
