export const showKeyList = {
  showKey: [
    "name",
    "intro",
    "serverCharacter",
    "address",
    "contact",
    "contactNumber",
    "contactEmail",
    "businessLicense"
  ],
  editKey: [
    "name",
    "intro",
    "serverCharacter",
    "address",
    "contact",
    "contactNumber",
    "contactEmail",
    "businessLicense"
  ]
};
