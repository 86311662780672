const fieldProps = {
  name: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "name",
    label: "服务机构名称",
    dbType: "string",
    compProps:{disabled:true},
    rules: [
      { required: true, message: "此项为必填项" },
      {
        max: 30,
        message: "长度限制30字以内"
      }
    ]
  },
  intro: {
    dataFormartType: "normalInput",
    inputType: "TextArea",
    formField: "intro",
    label: "服务机构简介",
    dbType: "string",
    compProps: { rows: 4, style: { minHeight: "120px" } },
    rules: [
      {
        max: 2000,
        message: "长度限制2000字以内"
      }
    ]
  },
  serverCharacter: {
    dataFormartType: "normalSelect",
    inputType: "Select",
    formField: "serverCharacter",
    label: "机构性质",
    chooseList: "query.typeList",
    chooseId: "id",
    dbType: "number"
  },
  address: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "address",
    label: "机构地址",
    dbType: "string",
    rules: [
      {
        max: 100,
        message: "长度限制100字以内"
      }
    ]
  },
  contact: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "contact",
    label: "联系人",
    dbType: "string",
    rules: [
      {
        max: 30,
        message: "长度限制30字以内"
      }
    ]
  },
  contactNumber: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "contactNumber",
    label: "联系电话",
    dbType: "string",
    rules: [
      {
        max: 20,
        message: "长度限制20字以内"
      },
    ]
  },
  contactEmail: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "contactEmail",
    label: "邮箱",
    dbType: "string",
    compProps:{disabled:true},
    // rules: [
    //   {
    //     max: 12,
    //     message: "长度限制12字以内"
    //   }
    // ]
  },
  businessLicense: {
    dataFormartType: "normalInput",
    inputType: "FileUpload",
    formField: "businessLicense",
    label: "营业执照",
    dbType: "array",
    compProps: {
      accept: "image/jpeg, image/jpg, image/png",
      // extra: "支持格式：jpg、png等",
      maxLength: 1,
      disabledSameName: true
    }
  }
};

const serverOrgFieldProps = { fieldProps };
export default serverOrgFieldProps;
