import React, { Component } from "react";
import Login from "./Login";
import Register from "./Register";
import Forget from "./Forget";
// import { Form, Input, Button, Row, Col, Icon } from "antd";
// import { connect } from "dva";
// import { router } from "umi";
// import throttle from "lodash/throttle";
import {  Route } from "react-router-dom";
import loginImg from "../../assets/login.png";
import registerImg from "../../assets/register.png";
import forgetImg from "../../assets/forget.png";
import { getSession } from "../../utils/utils";
import axios from "axios";
// import styles from "./Login.less";
import "./index.less";

// const FormItem = Form.Item;
// const FormItemLayout = {
//     labelCol: {
//         span: 5,
//     },
//     wrapperCol: {
//         span: 19,
//     },
// };

// @connect(({ user, loading }) => ({
//     captcha: user.captcha,
//     currentUser: user.currentUser,
//     loading: loading.effects["user/login"],
// }))
// @Form.create()
const url = ["/user/login", "/user/register", "/user/forget"];

class LoginPage extends Component {
  constructor(props) {
    super(props);

    // 1s 内只能触发一次
    // this.alterCaptcha = throttle(this.alterCaptcha, 1000);
  }

  componentDidMount() {
    const { history } = this.props;
    getSession(history);
    if (history.location.pathname !== "/user") {
      history.push(history.location.pathname);
    } else {
      history.push(url[0]);
    }
    if (url.indexOf(history.location.pathname) !== -1) {
      this.setState({
        current: url.indexOf(history.location.pathname)
      });
    }
  }

 

  // onSubmit = (e) => {
  //     e.preventDefault();
  //     const { loading, form, dispatch } = this.props;
  //     if (loading) {
  //         return;
  //     }
  //     form.validateFields((err, values) => {
  //         if (err) return;
  //         dispatch({
  //             type: "user/login",
  //             payload: values,
  //             callback: (res) => {
  //                 if (res && res.code === 200) {
  //                     dispatch({
  //                         type: "user/getSession",
  //                         payload: { isUser: true },
  //                     });
  //                 } else if (res && res.code !== 200) {
  //                     // 如若错误，重新获取验证码
  //                     this.getCaptcha();
  //                 }
  //             },
  //         });
  //     });
  // };
  toRegister = () => {
    const { history } = this.props;
    history.push("/user/register");
  };

  toLogin = () => {
    const { history } = this.props;
    history.push("/user/login");
  };

  toForget = () => {
    const { history } = this.props;
    history.push("/user/forget");
  };

  render() {
    const { history } = this.props;
    let urls = "";
    if (history.location.pathname === url[0]) {
      urls = loginImg;
    } else if (history.location.pathname === url[1]) {
      urls = registerImg;
    } else if (history.location.pathname === url[2]) {
      urls = forgetImg;
    }
    return (
      <div className="wrap">
        <img className="loginLeft" src={urls} width={100} height={100} alt="" />
        <div className="loginCard">
          <div className="loginForm">
            <Route path="/user/login" component={Login} {...this.props} />
            <Route path="/user/register" component={Register} {...this.props} />
            <Route path="/user/forget" component={Forget} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
