/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import "./index.less";

class ListItem extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { data, type, choosed } = this.props;
    let label = "";
    let info = "";
    if (type) {
      switch (type) {
        case "serverPark":
          label = "对接人";
          info = data.park&&data.park.counterpart;
          break;
        case "message":
          label = "审批结果";
          info = data.examine;
          break;
        case "project":
          label = "服务类别";
          info = data.type;
          break;
        case "case":
          label = "服务项目";
          info = data.prjName;
          break;
        default:
          break;
      }
    }

    return (
      <div className={choosed ? "itemChoosed" : "item"}>
        {type !== "message" ? (
          <div className="item_detail">
            <div className="item_name">
              {data.name}
              {type === "case" ? "成功案例" : ""}
            </div>
            <div className="item_info">
              {label}
              {label ? "：" : ""}
              {info}
            </div>
          </div>
        ) : (
          <div>
            <div className="item_message">
              <div style={{ display: "flex" }}>
                {data.isRead ? (
                  <div className="item_dot2"></div>
                ) : (
                  <div className="item_dot"></div>
                )}
                <div className="item_message_name">{data.name}服务推荐申请</div>
              </div>
              <div className="item_message_time">{data.auditingTime}</div>
            </div>
            <div className="item_info_message">
              {label}
              {label ? "：" : ""}
              <span
                className={
                  info
                    ? info === "已同意"
                      ? "item_message_info1"
                      : "item_message_info2"
                    : ""
                }
              >
                {info}
              </span>
            </div>
          </div>
        )}
        <div className="item_line"></div>
      </div>
    );
  }
}
export default ListItem;
