import { isDataType } from "../../utils/utils";
import { constant } from "../../utils/constant";

export const formartFieldProps = (prop = {}, listObj = {}) => {
  const result = { ...prop };
  const allListObj = { constant, ...listObj };
  if ("chooseList" in prop && !isDataType(prop.chooseList, "Array")) {
    const nameStr =
      (prop.chooseList &&
        isDataType(prop.chooseList, "String") &&
        prop.chooseList.split(".")) ||
      [];
    result.chooseList =
      (allListObj[nameStr[0]] && allListObj[nameStr[0]][nameStr[1] || ""]) ||
      [];
  }
  if (prop.compProps) {
    result.compProps = formartFieldProps(prop.compProps, listObj);
  }
  return result;
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 }
  }
};

export const setArrValue = value => {
  let res = [];
  if (value && value instanceof Array) {
    res = value;
  }
  if (value && typeof value === "string") {
    res = JSON.parse(value);
  }
  console.log(res);
  return res;
};
