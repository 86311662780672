import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
// import { LocaleProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import LoginPage from "./pages/User/index";
import "moment/locale/zh-cn";
import App from "./App";

const routes = (
  <ConfigProvider locale={zh_CN}>
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/user/login" />
        <Route path="/user" component={LoginPage} />
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>
  </ConfigProvider>
);

export default routes;
