/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { message } from "antd";
import ListCard from "../../components/ListCard";
import ListItem from "../../components/ListItem";
import EditFormModal from "../../components/ShowFieldComp/EditFormModal";
import FieldShowCard from "../../components/ShowFieldComp/FieldShowCard";
import { showKeyList } from "./unit";
import { getSession } from "../../utils/utils";
import axios from "axios";
import projectFieldProps from "../../utils/fieldsConfig/project";
import queryList from "../../graphql/project";
import "./index.less";

const { fieldProps } = projectFieldProps;

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: 0,
      editVisible: false,
      detail: null,
      editData: null,
      list: [],
      page: 1,
      pageSize: 8,
      isClick: true
    };
  }

  componentDidMount() {
    const { history }=this.props;
    this.getList();
    getSession(history);
  }

  getList = (param) => {
    const { page, pageSize ,itemId } = this.state;

    const obj = {
      query: queryList.projectList.query,
      variables: {
        input: {
          page,
          pageSize: param || pageSize
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res && res.data.data && res.data.data.serverProjects) {
        const count = res.data.data.serverProjects.count;
        const data = res.data.data.serverProjects.rows;
        this.setState({
          list: data,
          count
        });

        if (data.length !== 0) {
          if (itemId) {
            this.getDetail(itemId)
          } else {
            this.getDetail(data[0].id)
          }
        }
      }
    });
  };

  getDetail = id => {
    if (!id) {
      return;
    }
    const obj = {
      query: queryList.detail.query,
      variables: { id: Number(id) }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (
        res &&
        res.data.data &&
        res.data.data.serverProject &&
        res.data.data.serverProject
      ) {
        this.setState({
          detail: res.data.data.serverProject,
          itemId:id
        });
      }
    });
  };

  updateList = index => {
    const { page, pageSize } = this.state;
    const obj = {
      query: queryList.projectList.query,
      variables: {
        input: {
          page,
          pageSize
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res && res.data.data && res.data.data.serverProjects) {
        const count = res.data.data.serverProjects.count;
        const data = res.data.data.serverProjects.rows;
        this.setState({
          list: data,
          count
        });
        if (data.length !== 0) {
          const newIndex = index - 1 !== -1 ? index - 1 : 0;
          this.setState({
            itemId: data[newIndex] && data[newIndex].id
          });
          this.getDetail(data[newIndex] && data[newIndex].id);
        } else {
          this.setState({
            detail: {}
          });
        }
      }
    });
  };

  clickExtra = () => {
    this.setState({
      editVisible: true
    });
  };

  clickItem = id => {
    this.setState({
      itemId: id
    });
    this.getDetail(id);
  };

  save = values => {
    const { editData, isClick } = this.state;
    if (!isClick) {
      return;
    }
    this.setState({
      isClick: false
    });
    let status = "add";
    if (editData) {
      values.id = Number(editData.id);
      status = "update";
    }

    const obj = {
      query: queryList.mutation(status).query,
      variables: { input: { ...values } }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (
        res.data &&
        res.data.data &&
        res.data.data[`${status}ServerProject`].code === 200
      ) {
        message.success(status === "add" ? "新增成功!" : "更新成功!");
        this.getList();
        this.cancel();
      } else {
        message.error(res.data.data[`${status}ServerProject`].msg);
        this.getList();
      }
    });
    setTimeout(() => this.setState({ isClick: true }), 1000);
  };

  cancel = () => {
    this.setState({
      editVisible: false,
      editData: null
    });
  };

  clickData = (data, type) => {
    if (!data) return;
    if (type === "修改") {
      this.setState({
        editVisible: true,
        editData: data
      });
    }
    if (type === "删除") {
      this.del(Number(data.id));
    }
    if (type === "手动更新") {
      this.getReflesh(Number(data.id));
      // this.getDetail(Number(data.id));
      // setTimeout(() => {
      //   message.success("更新成功！");
      // }, 1000);
    }
  };

  // 手动更新
  getReflesh = id => {
    if (!id) {
      return;
    }
    const obj = {
      query: queryList.updateServiceXC.query,
      variables: { id: Number(id) }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (
        res &&
        res.data.data &&
        res.data.data.updateServiceXC &&
        res.data.data.updateServiceXC.code === 200
      ) {
        message.success("手动更新成功！");
      } else {
        message.error(res.data.data.updateServiceXC.msg);
      }
    });
  };

  del = id => {
    const { list } = this.state;
    const index = list.findIndex(e => e.id - 0 === id);
    const obj = {
      query: queryList.mutation("delete").query,
      variables: { id: id }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (
        res.data &&
        res.data.data &&
        res.data.data.deleteServerProject.code === 200
      ) {
        message.success("更新成功！");
        this.updateList(index);
        this.cancel();
      } else {
        message.error(res.data.data.deleteServerProject.msg);
      }
    });
  };

  // delete = id => {
  //   const { confirm } = Modal;
  //   confirm({
  //     title: "确认删除当前服务吗？",
  //     okText: "删除",
  //     okType: "danger",
  //     cancelText: "取消",
  //     onOk: () => {
  //       this.del(Number(id));
  //     },
  //     onCancel() {}
  //   });
  // };

  getMore = () => {
    const { page, pageSize } = this.state;
    const newPageSize = (page + 1) * pageSize;
    this.getList(newPageSize);
    this.setState({
      pageSize: newPageSize
    });
  };

  render() {
    const {
      itemId,
      editVisible,
      detail,
      editData,
      list,
      count,
      pageSize
    } = this.state;
    const editTitle = editData ? "修改服务" : "新增服务";
    const cardExtre = ["手动更新", "修改", "删除"];

    // let fieldProp = {};
    // const listObj = { query: { typeList: typeList } };
    // showKeyList.showKey.forEach(e => {
    //   fieldProp[e] = formartFieldProps(fieldProps[e], listObj);
    // });
    return (
      <div className="project">
        <div className="leftList">
          <ListCard
            title="服务园区列表"
            extra="新增服务"
            clickExtra={this.clickExtra}
            isMore={count > pageSize}
            getMore={this.getMore}
          >
            {list &&
              list.length !== 0 &&
              list.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    onClick={() => this.clickItem(item.id)}
                  >
                    <ListItem
                      data={item}
                      type="project"
                      choosed={item.id === itemId && true}
                    />
                  </div>
                );
              })}
            {/* {list && list.length >= count ? (
              <div className="noMore">没有更多啦～</div>
            ) : (
              <div onClick={this.getMore} className="getMore">
                加载更多
              </div>
            )} */}
          </ListCard>
        </div>

        <div className="rightInfo">
          {list && list.length !== 0 && (
            <div className="rightCard">
              <FieldShowCard
                cardExtre={cardExtre}
                title={detail && detail.name}
                keyList={showKeyList.showKey}
                fieldProps={fieldProps}
                data={detail ? detail : {}}
                clickData={this.clickData}
              />
            </div>
          )}
        </div>

        <EditFormModal
          visible={editVisible}
          title={editTitle}
          onCancel={this.cancel}
          save={this.save}
          editList={showKeyList.editKey}
          fieldProps={fieldProps}
          data={editData ? editData : {}}
          width="600px"
        />
      </div>
    );
  }
}
export default Project;
