/* 
  props.value的类型为
  [{id:1, file_id: '123asdf.jpg', fileName: 'file.jpg'}, {id:2, file_id: 'sfdjfasjfljdflmla.jpg', fileName: 'wenjian.jpg'}]
  keyValue: id,
  valueKey: file_id
  nameKey: fileName
*/

import React, { PureComponent } from "react";
import { Upload, Icon, Button, message } from "antd";

function S4() {
  return ((1 + Math.random()) * 0x100000000 || 0).toString(16).substring(1);
}

function guid() {
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}

export default class FileUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.data = null;
    this.filesList = [];
    this.state = {
      data: null,
      filesList: []
    };
  }

  setOriginFileList = props => {
    const { value, keyValue, valueKey, nameKey } = props;
    let tempValue = value || [];
    if (typeof tempValue === "string") {
      tempValue = JSON.parse(tempValue);
    }
    const res = tempValue.map(ele => {
      const fileUrl = `/api/v1/file/${ele[valueKey || "file_id"]}`;
      return {
        uid: keyValue ? ele.keyValue : guid(),
        name: ele[nameKey || "fileName"],
        status: "done",
        url: fileUrl
      };
    });
    return res;
  };

  handleFileChange = ({ file, fileList }) => {
    const { onChange } = this.props;
    let tempData = this.data ? JSON.parse(JSON.stringify(this.data)) : [];
    let tempFileList = JSON.parse(JSON.stringify(fileList));
    let uploadStatus = false;
    if (file.status === "done") {
      const { response } = file;
      const success = response && response.code === 200;
      const imgUrl = success ? `/api/v1/file/${response.data.file_id}` : "";
      const item = {
        uid: `(upload-)${file.uid}`,
        name: file.name,
        status: success ? "done" : "error",
        url: imgUrl
      };
      tempFileList = tempFileList.map(ele =>
        ele.uid === file.uid ? item : ele
      );
      uploadStatus = true;
    } else if (file.status === "removed") {
      tempFileList = fileList;
      uploadStatus = true;
    }

    tempData = this.setUpDateData(tempFileList);
    // this.filesList = tempFileList;
    this.data = tempData;
    this.setState({
      filesList: tempFileList,
      data: tempData
    });
    if (uploadStatus) {
      onChange(tempData);
    }
  };

  setUpDateData = (list = []) => {
    const { keyValue, valueKey, nameKey } = this.props;
    const tempList = [];
    list.forEach(ele => {
      if (ele.status === "done") {
        const listItem = {};
        listItem[valueKey || "file_id"] = ele.url.replace("/api/v1/file/", "");
        listItem[nameKey || "fileName"] = ele.name;
        if (keyValue && ele.uid.indexOf("(upload-)") === -1) {
          listItem[keyValue] = ele.uid;
        }
        tempList.push(listItem);
      }
    });
    return tempList;
  };

  beforeUpload = file => {
    const { accept } = this.props;
    if (accept) {
      const isType = accept.indexOf(file.type) !== -1;
      if (!isType) {
        message.error("不支持当前格式的文件上传");
      }
      return isType;
    }
    return true;
  };

  render() {
    const { filesList, data } = this.state;
    const { maxLength, uploadText, accept, extra, value } = this.props;

    this.filesList =
      filesList && filesList.length !== 0
        ? filesList
        : this.setOriginFileList(this.props);
    this.data = data || value;
    return (
      <Upload
        action="/api/v1/file/upload"
        // disabled={maxLength && filesList.length>=maxLength}
        fileList={this.filesList}
        accept={
          accept ||
          "image/jpeg, image/jpg, image/png, application/pdf,application/x-zip-compressed"
        }
        onChange={e => this.handleFileChange(e)}
        multiple={!maxLength}
        beforeUpload={this.beforeUpload}
      >
        <Button disabled={maxLength && this.filesList.length >= maxLength}>
          <Icon type="upload" />{" "}
          <span style={{ paddingRight: "8px" }}>{uploadText || "上传"} </span>
        </Button>
        {extra && <span style={{ marginLeft: 24 }}>{extra}</span>}
      </Upload>
    );
  }
}
