/* 
  props.value的类型为
  1. string : XXXXXXX... .jpg
  2. array: [xxxxxx.png, yyyyyy.jpg, zzzzz.sss] 
  3. array: [{id: 1, fileId: xxxxxxx.jpg}, {id: 2, fileId: sdfasfaf.fff}]

  根据keyValue判断类型

  valueKey 为类型3 fileId 的键值, 
*/

import React, { PureComponent } from 'react';
import { Upload, Icon, Modal, message } from 'antd'

function S4() {
  return (((1 + Math.random()) * 0x100000000) || 0).toString(16).substring(1);
}

function guid() {
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}

export default class PicUpload extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.value,
      filesList: this.setOriginFileList(props),
      previewImage: null,
      previewVisible: false,
    }
  }

  setOriginFileList = props => {
    const { value, keyValue, imgName, valueKey, labelKey } = props
    const imageReg = /^https?:\/\/.+$/;
    let res = []
    if(value && typeof value === 'string') {
      const imgUrl = imageReg.test(value) ? value : `/api/v1/file/${value}`;
      res = [{
        uid: guid(),
        name: imgName || value,
        status: 'done',
        url: imgUrl,
      }] 
    }
    if(value instanceof Array) {
      res = value.map(ele => {
        const imgUrl = keyValue? `/api/v1/file/${ele[valueKey] || ele.fileId}` : `/api/v1/file/${ele}`
        return {
          uid: keyValue? ele[keyValue] : guid(),
          name: keyValue? ele[labelKey || keyValue]  : ele,
          status: 'done',
          url: imgUrl,
        }
      })
    }
    return res
  }

  handlePreview = (file) => {
    // console.log(file)
    const { url } = file
    this.setState({
      previewVisible: true,
      previewImage: url
    })
  }

  handleChange = (files) => {
    const { onChange } = this.props
    const { file, fileList } = files
    const { data } = this.state
    let tempData = data && JSON.parse(JSON.stringify(data))
    let tempFileList = JSON.parse(JSON.stringify(fileList))
    let removeFile = null
    let addFile = null
    let uploadStatus = false
    if(file.status === 'done') {
      const { response } = file
      if(response && response.code === 200) {
        const imgUrl = `/api/v1/file/${response.data.file_id}`
        const item = {
          uid: `(upload-)${file.uid}`,
          name: file.name,
          status: 'done',
          url: imgUrl,
        }
        tempFileList = tempFileList.map(ele => (ele.uid === file.uid? item : ele))
        addFile = item
        // tempFileList.push(item)
        uploadStatus = true
      } else {
        message.error('上传失败')
      }
    } else if(file.status === 'removed') {
      tempFileList = fileList
      removeFile = file
      uploadStatus = true
    } else if(file.status === 'uploading'){
      tempFileList = fileList
    }
    // console.log('fileList:::', fileList)
    tempData = this.setUpDateData(tempFileList, addFile, removeFile)
    this.setState({
      filesList: tempFileList,
      data: tempData
    })
    if(uploadStatus) {
      onChange(tempData)
    }
  }

  setUpDateData = (list = [], addFile, removeFile) => {
    // console.log(removeFile)
    const { keyValue, valueKey } = this.props
    const { data } = this.state    
    let res = data && JSON.parse(JSON.stringify(data))
    if(!data || typeof data === 'string'){
      res = list[0] && list[0].url && list[0].url.replace('/api/v1/file/', "") || ""
    }else if(data instanceof Array) {
      if(addFile) {
        res.push({
          flag: 'add',
          [valueKey || 'fileId']: addFile.url.replace('/api/v1/file/', ""),
          [keyValue || 'id']: addFile.uid
        })
      }
      if(removeFile) {
        if(removeFile.uid.indexOf('(upload-)')===-1) {    // 原始文件
          res = data.map(ele => ele[keyValue]===removeFile.uid? {...ele, flag: 'delete'} : ele)
        }else {
          res = data.filter(ele => ele[keyValue || 'id'] !== removeFile.uid)
        }
      }
    }
    return res
  }

  handleCancel = () => {
    this.setState({
      previewVisible: false,
      previewImage: null,
    })
  }

  render() {
    const { filesList, previewImage, previewVisible, data } = this.state
    const { length } = this.props
    const maxLength = data && typeof data === 'string' ? 1 : length
    // console.log('render', maxLength)
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    // console.log(maxLength, data)
    return (
      <div>
        <Upload
          name="logo"
          action="/api/v1/file/upload"
          listType="picture-card"
          accept="image/jpeg, image/jpg, image/png"
          fileList={filesList}
          onPreview={this.handlePreview}
          onChange={(e)=>this.handleChange(e)}
          disabled={maxLength===0}
        >
          {maxLength && filesList.length >= maxLength ? null : uploadButton}
        </Upload>
        {/* <div style={{color:'red'}}>只支持 .jpg格式</div> */}

        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    )
  }
}