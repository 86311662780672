const message = {
  query: `query($input:ApplicationQuery){
        applications(input:$input){ 
            count
            rows{
                id
                serverOrg_id
                auditingStatus
                auditingTime
                remark
                comment
                isRead
                createdAt
                park{
                    id
                    name
                    counterpart
                    counterpartNum
                }
         }
          }
        }`
};

const messageDetail = {
  query: `query($id: ID!){
    application(id:$id){  
            id
            serverOrg_id
            auditingStatus
            auditingTime
            remark
            comment
            isRead
            createdAt
            park{
                id
                name
                counterpart
                counterpartNum
            }
      
      }
    }`
};

const mutation = {
  query: `mutation($id:ID!){ 
    deleteApplication(id:$id){
            code
            msg
          } 
        }
        `
};

const queryList = {
  message,
  messageDetail,
  mutation
};

export default queryList;
