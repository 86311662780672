
// 成功案例列表
export const getserverCasesQuery = {
  query: `query($input: ServerCaseQuery){
      serverCases(input:$input){
        count
        rows{
          id
          service_id
          serverOrg_id
          img
          companyName
          advantage
          details
          prjName
          
        }
      }
    }`
};

// 成功案例详情
export const getserverCase = {
  query: `query($id:  ID!){
      serverCase(id:$id){
          id
          service_id
          serverOrg_id
          img
          companyName
          advantage
          details
          prjName
          serverParkCases{
            id
            serverPark_id
            yq_case_id
            recommendStatus
            createdAt
          }
      }
    }`
}

// 新增成功案例
export const addServerCase = {
  query: `mutation($input: AddServerCase){
      addServerCase(input:$input){
        code
        msg
      }
    }`
};

//   修改成功案例
export const updateServerCase = {
  query: `mutation($input:  UpdateServerCase){
        updateServerCase(input:$input){
      code
      msg
    }
  }`
};

//   删除成功案例
export const deleteServerCase = {
  query: `mutation($id:  ID!){
        deleteServerCase(id:$id){
      code
      msg
    }
  }`
};

// 获取所有服务----关联服务
export const getAllProjectsQuery = {
  query: `query{
      getAllProjects{
        id
        name
      }
    }`
};

export const updateCaseXC = {
  query: `mutation($id:ID!){ 
      updateCaseXC(id:$id){
        code
        msg
      } 
    }
    `
};
