import React, { Component } from "react";
import ServerPark from "./pages/ServerPark";
import Message from "./pages/Message";
import Project from "./pages/Project";
import ServerOrgInfo from "./pages/System/ServerOrgInfo";
import Password from "./pages/System/Password";
import Version from "./pages/System/Version";
import Case from "./pages/Case";
import Heard from "./components/Heard";
import { Route, Switch } from "react-router-dom";
import { getSession } from "./utils/utils";
import queryList from "./graphql/system";
import axios from "axios";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail:{}
    };
  }

  componentDidMount() {
    const { history }=this.props
    getSession(history);
    this.getList()
  }

  

  getList = () => {
    const obj = {
      query: queryList.serverOrg.query
    };
    axios.post(`/graphql`, obj).then(res => {
      if(res.data&&res.data.data&&res.data.data.serverOrg){
      this.setState({
        detail: res.data.data.serverOrg
      });
    }
    });
  };

  render() {
    const { detail }=this.state;
    return (
      <div className="incubator">
        <Heard {...this.props} details={detail} />
        <div className="incubator-content">
          <Switch>
            <Route path="/message" component={Message} />
            <Route path="/serverPark" component={ServerPark} />
            <Route path="/project" component={Project} />
            <Route path="/case" component={Case} />
            <Route path="/system/serverOrgInfo" component={ServerOrgInfo} />
            <Route path="/system/password" component={Password} />
            <Route path="/system/version" component={Version} />
            {/* <Redirect from="*" to="/" /> */}
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
