const fieldProps = {
  name: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "name",
    label: "服务名称",
    dbType: "string",
    rules: [
      { required: true, message: "此项为必填项" },
      {
        max: 20,
        message: "长度限制20字以内"
      }
    ]
  },
  type: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "type",
    label: "服务类别",
    // chooseList: "query.typeList",
    // chooseId: "id",
    dbType: "string",
    rules: [
      {
        max: 20,
        message: "长度限制20字以内"
      }
    ]
  },
  item: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "item",
    label: "服务项目",
    dbType: "string",
    rules: [
      {
        max: 20,
        message: "长度限制20字以内"
      }
    ]
  },
  advantage: {
    dataFormartType: "normalInput",
    inputType: "Input",
    formField: "advantage",
    label: "服务优势",
    dbType: "string",
    rules: [
      {
        max: 50,
        message: "长度限制50字以内"
      }
    ]
  }
};

const projectFieldProps = { fieldProps };
export default projectFieldProps;
