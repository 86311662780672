/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Button, Form, Input,  Row, Col, message } from "antd";
import { getSessions } from "../../utils/utils";
import axios from "axios";
import "./index.less";

const FormItemLayout = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 19
  }
};

let maxTime = 60

class Login extends Component {
  constructor(props) {
    super(props);

    this.state={
      confirmDirty:false,
      btnText: '发送验证码',
      btnBool: false,
      disableds:false
    }
  }

 validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value&&confirmDirty) {
      form.validateFields(["password1"], { force: true });
    }
    if (value) {
      if (!/^[a-zA-Z0-9]+$/.test(value)) {
        return callback("输入英文或者数字组成密码");
      } 
      if (value.length < 6 || value.length > 16) {
        return callback("输入6-16位密码");
      }
    }
    return callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次密码输入不一致");
    } else {
      callback();
    }
  };

  // 登录
  onSubmit = () => {
    const {
      form: { validateFieldsAndScroll },
      history
    } = this.props;
    validateFieldsAndScroll((err, value) => {
      if (!err) {
        axios
          .post(`/api/v1/user/login`, value)
          .then(res => {
            if (res.data.code === 200) {
              message.success("登录成功");
              getSessions(history, message);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch(err => {
            message.error(err);
          });
      }
    });
  };

  toRegister = () => {
    const { history } = this.props;
    history.push("/user/register");
  };

  toForget = () => {
    const { history } = this.props;
    history.push("/user/forget");
  };

    // 获取验证码
    alterCaptcha = () => {
      const {
        form
      } = this.props;
      
      const value=form.getFieldValue('email')
          if (value) {
            this.setState({
              disableds: true
            })
            maxTime = 60
            axios.get(`/api/v1/user/captcha?email=${value}&action=login&devEmail=${value}`)
              .then((res) => {
                if(res&&res.data&&res.data.code===200){
                  this.setState({
                    disableds: false
                  })
                  this.timer = setInterval(() => {
                    if (maxTime > 0) {
                      --maxTime
                      this.setState({
                        btnText: '重新获取' + maxTime,
                        btnBool: true
                      })
                    }
                    else {
                      this.setState({
                        btnText: '发送验证码',
                        btnBool: false
                      })
                      clearInterval(this.timer)
                    }
                  }, 1000)
                  message.success(res.data.msg);
                }else {
                  this.setState({
                    disableds: false
                  })
                 message.error(res.data.msg);
                }
              })
              .catch(err => {
                message.error(err)
              })
          } else {
            message.error("邮箱号不能为空！");
          }
    }

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { btnBool ,btnText , disableds }=this.state;
    return (
      <div className="loginForms">
        <Form
          layout="horizontal"
          // onSubmit={this.onSubmit}
          labelAlign="left"
          colon={false}
          {...FormItemLayout}
        >
          <Form.Item label="邮箱" className="formItems">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "请填写邮箱" },
                { type: 'email', message: "请输入正确的邮箱格式" }
              ]
            })(<Input placeholder="请填写邮箱" allowClear />)}
          </Form.Item>

          <Form.Item label="验证码">
            <Row gutter={8} style={{ overflow: "hidden" }}>
              <Col span={14}>
                {getFieldDecorator("mailCode", {
                  rules: [
                    { required: true, message: "请输入验证码" },
                    {
                      pattern: /^[0-9a-zA-Z]+$/,
                      message: "请输入英文字母或数字",
                    },
                  ],
                })(<Input maxLength={8} autoComplete="off" allowClear />)}
              </Col>
              <Col span={10}>
                <Button
                  style={{ backgroundColor: '#3C6CA2', color: '#fff' }}
                  onClick={this.alterCaptcha.bind(this)}
                  disabled={btnBool}
                  loading={disableds}
                >
                  {btnText}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="密码" className="formItems">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "请输入密码" }]
            })(<Input type="password" placeholder="请输入密码" allowClear />)}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={this.onSubmit}
            // loading={loading}
            style={{ width: "100%", marginTop: 6 }}
          >
            登录
          </Button>
        </Form>
        <div className="title">
          <div>
            还没有账号，去
            <span
              style={{ cursor: "pointer", color: "#268CFF" }}
              onClick={this.toRegister}
            >
              注册
            </span>
          </div>
          <span
            style={{ cursor: "pointer", color: "#268CFF" }}
            onClick={this.toForget}
          >
            忘记密码
          </span>
        </div>
      </div>
    );
  }
}

const Logins = Form.create({})(Login);
export default Logins;
