const projectList = {
  query: `query($input: ServerProjectQuery){
      serverProjects(input:$input){ 
        count
        rows{
          id
          serverOrg_id
          name
          type
          item
          advantage  
          serverParkProjects{
            id
            serverPark_id
            recommendStatus
            createdAt
          }
        }
      }
    }`
};

const detail = {
  query: `query($id: ID!){
      serverProject(id:$id){ 
          id
          serverOrg_id
          name
          type
          item
          advantage  
          serverParkProjects{
            id
            serverPark_id
           yq_project_id
            recommendStatus
            createdAt
          }
        } 
    }`
};

const mutation = status => {
  if (status !== "delete") {
    return {
      query: `mutation($input:${
        status === "add" ? "AddServerProject" : "UpdateServerProject"
        }){
        ${status}ServerProject(input:$input){
          code
          msg
        } 
      }
      `
    };
  }
  return {
    query: `mutation($id:ID!){ 
      ${status}ServerProject(id:$id){
        code
        msg
      } 
    }
    `
  };
};

const updateServiceXC = {
  query: `mutation($id:ID!){ 
      updateServiceXC(id:$id){
        code
        msg
      } 
    }
    `
};

const queryList = {
  projectList,
  detail,
  mutation,
  updateServiceXC
};

export default queryList;
