/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Button, Table, Divider, Modal, Checkbox, message } from "antd";
import axios from "axios";
import ListCard from "../../components/ListCard";
import ListItem from "../../components/ListItem";
import EditFormModal from "../../components/ShowFieldComp/EditFormModal";
import FieldShowCard from "../../components/ShowFieldComp/FieldShowCard";
import ShowFieldValue from "../../components/ShowFieldComp/ShowFieldValue";
import { showKeyList, appLiedShowKeyList } from "./unit";
import {
  getServerParksQuery,
  getserverParkQuery,
  addParkProject,
  getparksQuery,
  addServerPark,
  deleteServerPark,
  deleteParkProject
} from "../../graphql/servicePark";
import { getSession } from "../../utils/utils";
import queryList from "../../graphql/project";
import { serviceColumns } from "../../utils/tableListConfig";
import { formartFieldProps } from "../../components/ShowFieldComp/unit";
import serverParkFieldProps from "../../utils/fieldsConfig/serverPark";
import "./index.less";

const { fieldProps } = serverParkFieldProps;

class ServerPark extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      page: 1,
      pageSize: 5,
      pages: 1,
      pageSizes: 8,
      index: 1,
      countList:0,
      dataSource: [],
      data: [],
      parkList: [],
      itemId: 0,
      editVisible: false,
      addvisible: false,
      detail: {},
      park_id:"",
      serviceList: [],
      checkedAll: [],
      selectId: null,
      detailvisible: false,
      modalDetail: "",
      addOrDetailTitle: "",
      indexs:0,
      allId: [] //所有选中服务的id集合
    };
  }

  componentDidMount() {
    const { history }=this.props;
    this.getList();
    getSession(history);
    this.getAllPark();
  }

  // 获取园区列表
  getList = (index) => {
    const { pages, pageSizes ,itemId  } = this.state;
    const obj = {
      query: getServerParksQuery.query,
      variables: {
        input: {
          page:pages,
          pageSize: index || pageSizes
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res && res.data.data && res.data.data.serverParks) {
        const counts = res.data.data.serverParks.count;
        const datas = res.data.data.serverParks.rows;
        this.setState({
          data: datas,
          countList: counts
        });
        if (datas.length !== 0) {
          if (itemId) {
            this.getDetail(itemId)
          } else {
            this.getDetail(datas[0].id)
          }
        }
      }else {
        message.error(res.data.data.serverParks.msg);
      }
    });
    // .catch(err => {
    //    message.error(err);
    // });
  };

  updateList = index => {
    const {pages, pageSizes } = this.state;
    const obj = {
      query: getServerParksQuery.query,
      variables: {
        input: {
          page:pages,
          pageSize:pageSizes
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res && res.data.data && res.data.data.serverParks) {
        const counts = res.data.data.serverParks.count;
        const datas = res.data.data.serverParks.rows;
        this.setState({
          data: datas,
          countList: counts
        });
        if (datas.length !== 0) {
          const newIndex = index - 1 !== -1 ? index - 1 : 0;
          this.setState({
            itemId: datas[newIndex] && datas[newIndex].id
          });
          this.getDetail(datas[newIndex] && datas[newIndex].id);
        } else {
          this.setState({
            detail: {}
          });
        }
      }else {
        message.error(res.data.data.serverParks.msg);
      }
    });
  };

  // 获取所有园区
  getAllPark = () => {
    const obj = {
      query: getparksQuery.query
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (res && res.data && res.data.data && res.data.data.parks) {
          this.setState({
            parkList: res.data.data.parks
          });
        }else {
          message.error(res.data.data.parks.msg);
        }
      })
  };

  // 获取详情
  getDetail = (id) => {
    // const { itemId } = this.state;
    if (!id) {
      return;
    }
    const obj = {
      query: getserverParkQuery.query,
      variables: { id: id }
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (
          res &&
          res.data.data &&
          res.data.data.serverPark &&
          res.data.data.serverPark.park
        ) {
          this.setState({
            detail: res.data.data.serverPark.park,
            park_id:res.data.data.serverPark.id,
            itemId: id
          });
          this.getTableList(true);
        }else {
          message.error(res.data.data.serverPark.msg);
        }
      })
  };

  // 获取tableList
  getTableList = param => {
    const { page, pageSize ,park_id , dataSource } = this.state;
    let serverId = {}
    if (param) {
      serverId = {
        serverPark_id: park_id
      }
    }
    const obj = {
      query: queryList.projectList.query,
      variables: {
        input: {
          page,
          pageSize: pageSize,
          ...serverId,
          unRecommend: param //是否被推荐
        }
      }
    };
    axios.post(`/graphql`, obj).then(res => {
      if (res && res.data.data && res.data.data.serverProjects) {
        const count = res.data.data.serverProjects.count;
        const data = res.data.data.serverProjects.rows;
        if (param) {
          this.setState({
            dataSource: data,
            count
          });
        } else {
          // data去掉dataSource
          let datas=data
          if(data&&data.length!==0){
          data.forEach(key=>{
            if(dataSource&&dataSource.length!==0){
              dataSource.forEach(ee=>{
                if(ee.id===key.id){
                  datas=datas.filter(rr=>{
                    return rr.id!==key.id
                  })
                }
              })
            }
          })
        }
          let checkedIds = [];
          datas.forEach(ee => {
            checkedIds.push(ee.id)
          })
          this.setState({
            serviceList: datas,
            checkedAll:checkedIds
          });
        }
        if (data.length !== 0) {
          this.setState({
            modalDetail: data[0],
           
          });
        }
      }else {
        message.error(res.data.data.serverProjects.msg);
      }
    });
  };

  save = values => {
    const obj = {
      query: addServerPark.query,
      variables: {
        input: { ...values }
      }
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (res.data && res.data.data && res.data.data.addServerPark.code === 200) {
          message.success("已提交关联申请！")
          this.setState({
            editVisible: false
          });
          this.getList();
          this.getTableList(true);
        } else {
          message.error(res.data.data.addServerPark.msg);
          this.cancel();
        }
      })
  };

  clickExtra = () => {
    this.setState({
      editVisible: true
    });
    this.getAllPark()
  };

  clickItem = (id,index) => {
    // const { data } = this.state;
    // const detail = data.filter(e => e.id === id)[0];
    this.setState({
      itemId: id
      // detail
    });
    this.getDetail(id,index);
    this.getTableList(true);
  };

  cancel = () => {
    this.setState({
      editVisible: false,
      addvisible: false,
      detailvisible: false,
      allId: [],
      serviceList: []
    });
  };

  clickData = () => {
    const { itemId ,data } = this.state;
   
    const index = data.findIndex(e => e.id - 0 === Number(itemId));
    if (itemId) {
      const obj = {
        query: deleteServerPark.query,
        variables: { id: itemId }
      };
      axios
        .post(`/graphql`, obj)
        .then(res => {
          if (
            res &&
            res.data.data &&
            res.data.data.deleteServerPark &&
            res.data.data.deleteServerPark.code === 200
          ) {
            message.success("已取消园区关联！");
            this.updateList(index);
          }else {
            message.error(res.data.data.deleteServerPark.msg);
          }
        })
    }
  };

  // 查看详情
  goDetail = id => {
    const { dataSource } = this.state;
    const detail = dataSource.filter(e => e.id === id)[0];
    this.setState({
      detailvisible: true,
      modalDetail: detail,
      addOrDetailTitle: "纳税申报"
    });
  };

  // 新增服务推荐
  addService = () => {
    this.getTableList(false);
    this.setState({
      addvisible: true,
      addOrDetailTitle: "添加推荐服务"
    });
  };

  // 选中服务
  onChange = id => {
    const { allId } = this.state;
    let allIds = allId;
    if (allId.indexOf(id) !== -1) {
      allIds = allIds.filter(ee => ee !== id);
    } else {
      allIds.push(id);
    }
    this.setState({
      selectId: id,
      allId: allIds
    });
  };

  // 全选
  selectAll = () => {
    const { checkedAll } = this.state;
    // 取所有推荐服务的id
    this.setState({
      allId: checkedAll
    });
  };

  // 保存推荐服务
  handleOk = () => {
    const { allId, park_id } = this.state;
    const obj = {
      query: addParkProject.query,
      variables: {
        input: {
          serverPark_id: park_id,
          service_ids: allId
        }
      }
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (
          res &&
          res.data.data.addParkProject &&
          res.data.data.addParkProject.code === 200
        ) {
          message.success("推荐成功！");
          this.getTableList(true)
          // 获取到所有推荐服务的id
          this.setState({
            addvisible: false,
            allId: []
          });
        } else {
          message.error(res.data.data.addParkProject.msg);
        }
      })
  };

  // 翻页
  onTableChange = ({ current, pageSize }) => {
    this.setState({
      page: current,
      pageSize
    });
  };

  // 取消推荐
  cancelRecommendation = id => {
   
    const obj = {
      query: deleteParkProject.query,
      variables: { id }
    };
    axios
      .post(`/graphql`, obj)
      .then(res => {
        if (
          res &&
          res.data.data.deleteParkProject &&
          res.data.data.deleteParkProject.code === 200
        ) {
          this.getTableList(true);
          message.success("已取消推荐！");
          
        } else {
          message.error(res.data.data.deleteParkProject.msg);
        }
      })
  };

    // 加载更多
    getMore = () => {
      const { pageSizes, index } = this.state;
      this.getList((index + 1) * pageSizes);
     
      this.setState({
        index: index + 1,
        pageSizes: (index + 1) * pageSizes
      });
    };

  render() {
    const {
      data,
      itemId,
      editVisible,
      detail,
      page,
      pageSize,
      pageSizes,
      addvisible,
      dataSource,
      checkedAll,
      detailvisible,
      modalDetail,
      allId,
      addOrDetailTitle,
      parkList,
      serviceList,
      countList
    } = this.state;
    const editTitle = "添加关联园区";
    const editList = showKeyList.editKey;
    let fieldProp = {};
    const listObj = { query: { parkList: parkList } };
    editList.forEach(e => {
      fieldProp[e] = formartFieldProps(fieldProps[e], listObj);
    });
    const columns = [
      ...serviceColumns,
      {
        title: "操作",
        dataIndex: "id",
        key: "id",
        width: "15%",
        render: (text,row) => {
          return (
            <div>
              <span
                style={{ cursor: "pointer", color: "#268CFF" }}
                onClick={() => this.goDetail(text)}
              >
                详情
              </span>
              <Divider type="vertical" />
              <span
                style={{ cursor: "pointer", color: "#268CFF" }}
                onClick={() => this.cancelRecommendation(row.serverParkProjects[0].id)}
              >
                取消推荐
              </span>
            </div>
          );
        }
      }
    ];
    const isMore = countList > pageSizes ? true : false;
    
    let checkedAllFlag = false;
    const buttons = ["全部选中", "确认", "返回"];
    return (
      <div className="serverPark">
        <div className="leftList">
          <ListCard
            title="服务园区列表"
            extra={editTitle}
            clickExtra={this.clickExtra}
            isMore={isMore}
            getMore={this.getMore}
          >
            {data.map((item, index) => {
              item.name = item.park && item.park.name;
              return (
                <div
                  key={index.toString()}
                  onClick={() => this.clickItem(item.id,index)}
                >
                  <ListItem
                    data={item}
                    type="serverPark"
                    choosed={item.id === itemId && true}
                  />
                </div>
              );
            })}
          </ListCard>
        </div>
        <div className="rightInfo">
          {data && data.length !== 0 &&
            <div className="rightCard">
              <FieldShowCard
                cardExtre="取消该园区关联"
                title={detail && detail.name}
                keyList={showKeyList.showKey}
                fieldProps={fieldProps}
                data={detail && detail}
                clickData={this.clickData}
              />
            </div>
          }
          {data && data.length !== 0 &&
            <div className="rightBottom">
              <ListCard title="推荐服务管理" table>
                <Button
                  className="addService"
                  type="primary"
                  onClick={this.addService}
                  disabled={detail && detail.id ? false : true}
                >
                  新增推荐服务
              </Button>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  rowKey={record => record.id}
                  onChange={this.onTableChange}
                  pagination={{
                    current: page || 1,
                    pageSize: pageSize || 5
                    // showSizeChanger: true,
                    // showQuickJumper: true,
                    // pageSizeOptions: ["5", "10", "20"],
                    // showTotal: total => `共 ${total} 条`,
                    // size: "default",
                    // total: count || 0
                  }}
                />
              </ListCard>
            </div>
          }
        </div>
        <EditFormModal
          submitName="发送关联申请"
          visible={editVisible}
          title={editTitle}
          onCancel={this.cancel}
          save={this.save}
          editList={editList}
          fieldProps={fieldProp}
          data={{}}
          width="600px"
        />

        <Modal
          title={addOrDetailTitle}
          visible={addvisible || detailvisible}
          footer={null}
          width={addvisible ? 1000 : 500}
          closable={false}
        >
          {(addvisible || detailvisible) && addvisible ? (
            <div>
              <div className="addserviceListModals">
                {serviceList &&
                  serviceList.map((item, index) => {
                    let falg = false;
                    if (allId.indexOf(item.id) !== -1) {
                      falg = true;
                    }
                    // 判断是否全选
                    if (allId.length !== 0 && checkedAll.length !== 0 &&
                      JSON.stringify(allId.sort()) ===
                      JSON.stringify(checkedAll.sort())
                    ) {
                      checkedAllFlag = true;
                    }
                    return (
                      <div
                        className="addserviceListModal"
                        key={index.toString()}
                      >
                        <div className="addserviceList_content">
                          <div className="addserviceList">
                            <div className="serviceName">{item.name}</div>
                            <div className="serviceType">
                              服务类别：{item.type}
                            </div>
                          </div>
                          <div className="serviceCheckbox">
                            <Checkbox
                              checked={falg}
                              key={item.id}
                              onChange={() => this.onChange(item.id)}
                            ></Checkbox>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="addserviceList_button">
                {buttons.map((ee, index) => {
                  return (
                    <Button
                      key={index.toString()}
                      className="button"
                      type="primary"
                      disabled={ee === "全部选中" ? checkedAllFlag : false}
                      onClick={
                        (ee === "全部选中" && this.selectAll) ||
                        (ee === "确认" && this.handleOk) ||
                        (ee === "返回" && this.cancel)
                      }
                    >
                      {ee}
                    </Button>
                  );
                })}
              </div>
            </div>
          ) : (
              <div>
                <ShowFieldValue
                  keyList={appLiedShowKeyList.showKey}
                  fieldProps={fieldProps}
                  // clickData={clickData}
                  data={modalDetail}
                />
                <div className="ShowFieldValueModal">
                  <Button type="primary" onClick={this.cancel}>
                    返回
                </Button>
                </div>
              </div>
            )}
        </Modal>
      </div>
    );
  }
}
export default ServerPark;
