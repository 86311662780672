export const showKeyList = {
  showKey: ["counterpart", "counterpartNum"],
  editKey: ["park_id", "remark"]
};

export const appLiedShowKeyList = {
  showKey: ["name", "type","item","advantage"],
  editKey:  ["name", "type","item","advantage"]
};

export const xxx = "xxx";
