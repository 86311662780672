import React from "react";
import { Modal, Button, Form } from "antd";
import ShowFormEdit from "./ShowFormEdit";
import { formartSubmitData } from "./showUnit";

const EditFormModal = props => {
  const {
    visible,
    title,
    onCancel,
    save,
    submitName,
    loading,
    submitDisabled = false,
    width = 900,
    data = {},
    editList = [],
    fieldProps = {},
    form
  } = props;
  const { isFieldsTouched, validateFieldsAndScroll } = form;
  const touched = isFieldsTouched();

  const submit = () => {
    validateFieldsAndScroll((err, value) => {
      if (!err) {
        const result = formartSubmitData(value, data, editList, fieldProps);
        if (save) {
          save(result);
        }
      }
    });
  };

  const modelTitle =
    title !== null ? (
      <div
        className="text-ellipsis"
        style={{ width: "90%", height: "40px", lineHeight: "40px" }}
      >
        {title}
      </div>
    ) : null;
  const footer = (
    <div>
      <div style={{ height: "56px", lineHeight: "56px", textAlign: "center" }}>
        <Button
          className="btn-wider"
          style={{ marginLeft: 16 }}
          type="primary"
          onClick={submit}
          loading={loading}
          disabled={!touched || submitDisabled}
        >
          {submitName || "保存"}
        </Button>
        <Button
          className="btn-wider"
          style={{ marginLeft: 16 }}
          onClick={onCancel || (() => {})}
        >
          取消
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      title={modelTitle}
      visible={visible}
      footer={footer}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      closable={false}
      width={width}
    >
      <Form>
        <ShowFormEdit
          form={form}
          data={data}
          keyList={editList}
          fieldProps={fieldProps}
        />
      </Form>
    </Modal>
  );
};

const EditFormModalForm = Form.create({})(EditFormModal);
export default EditFormModalForm;
