/* eslint-disable no-use-before-define */
import React from "react";
import { Input, Select } from "antd";
import moment from "moment";
import LogoUpload from "../../components/FileUpload/LogoUpload";
import PicUpload from "../../components/FileUpload/PicUpload";
import FileUpload from "../../components/FileUpload/FileUpload";
import { setArrValue } from "./unit";
import "./index.less";

const { Option } = Select;
const { TextArea } = Input;
// const imageReg = /^https?:\/\/.+$/;
// const { Password } = Input;

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 }
  }
};

export const tableFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  }
};

export const normalInputShow = value => value || "";
export const normalInputEdit = value => value || "";
export const normalInputSubmit = (value, prop) => {
  const { formField } = prop;
  return { [formField]: value || "" };
};

export const normalSelectShow = (value, props) => {
  // console.log(value,props)
  const { chooseList, chooseId, showColorDisabled } = props;
  const list = chooseList || [];
  const target = list.find(
    ele => String(ele[chooseId || "id"]) === String(value)
  );
  let res =
    target && target.color && !showColorDisabled ? (
      <span style={{ color: `${target.color}` }}>{target.name}</span>
    ) : (
      (target && target.name) || "-"
    );
  if (target && props.onlyShowName) {
    res = target.name;
  }
  return res;
};
export const normalSelectEdit = value => value || "";
export const normalSelectSubmit = (value, prop) => {
  const { formField } = prop;
  return { [formField]: value || "" };
};

export const normalDateShow = (value, prop) => {
  // console.log(value, prop);
  let result = "";
  if (moment.isMoment(value)) {
    result = moment(value).format(prop.format);
  } else {
    result = value;
  }
  return result;
};
export const normalDateEdit = (value, prop) => {
  let result = null;
  if (value && !moment.isMoment(value)) {
    result = moment(value); // 编辑时转化为moment对象
  } else {
    result = value;
  }
  return result;
};
export const normalDateSubmit = (value, prop) => {
  const { formField } = prop;
  return { [formField]: value || "" };
  // return { [formField]: (moment.isMoment(value) && value.valueOf()) || "" };
};

export const normalFileShow = (value, prop) => {
  let result = "";

  const tempValue = setArrValue(value);
  result = tempValue.length
    ? tempValue.map((ele, index) => (
        <div key={index.toString()}>
          <a
            href={`/api/v2/file/${ele[prop.valueKey || "file_id"]}`}
            download={ele[prop.valueKey || "file_id"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {ele[prop.nameKey || "fileName"]}
          </a>
        </div>
      ))
    : "-";
  return result;
};
export const normalFileEdit = value => value || "";
export const normalFileSubmit = (value, prop) => {
  const { formField } = prop;
  return { [formField]: value || "" };
};

const normalInput = {
  show: normalInputShow,
  edit: normalInputEdit,
  submit: normalInputSubmit
};

const normalSelect = {
  show: normalSelectShow,
  edit: normalSelectEdit,
  submit: normalSelectSubmit
};

const normalDate = {
  show: normalDateShow,
  edit: normalDateEdit,
  submit: normalDateSubmit
};

const normalFile = {
  show: normalFileShow,
  edit: normalFileEdit,
  submit: normalFileSubmit
};

const dataFormartTypeObj = {
  normalInput,
  normalSelect,
  normalDate,
  normalFile
};

export const handleShowValue = (data, prop, loading, onClick) => {
  const { dataFormartType = "" } = prop;
  const func =
    dataFormartTypeObj[dataFormartType] &&
    dataFormartTypeObj[dataFormartType].show;

  const result = (func && func(data, prop, loading, onClick)) || "-";
  return result;
};

export const setFormInitialValue = (data, prop) => {
  const { dataFormartType = "" } = prop;
  const func =
    dataFormartTypeObj[dataFormartType] &&
    dataFormartTypeObj[dataFormartType].edit;
  const result = func && func(data, prop);
  return result;
};

export const setFormInputComp = (props, onChange) => {
  const { compProps = {} } = props;

  if (props.rules) {
    compProps.rules = props.rules;
  }

  let CompRef = Input;
  switch (props.inputType) {
    case "Select":
      CompRef = Select;
      break;
    case "LogoUpload":
      CompRef = LogoUpload;
      break;
    case "PicUpload":
      CompRef = PicUpload;
      break;
    case "TextArea":
      CompRef = TextArea;
      break;
    case "FileUpload":
      CompRef = FileUpload;
      break;
    default:
      break;
  }

  let result = (
    <CompRef {...compProps} options={props.chooseList} onChange={onChange} />
  );
  if (props.inputType === "Select" && !!props.chooseList) {
    result = (
      <CompRef
        // getpopupcontainer={containerFunc}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        tokenSeparators={["；"]}
        {...compProps}
        onChange={onChange}
        style={{ width: "100%" }}
      >
        {handleSelectOption(props)}
      </CompRef>
    );
  }

  return result;
};

export const handleSelectOption = props => {
  const list = props.chooseList || [];
  const labelName = props.chooseLabelKey ? props.chooseLabelKey : "name";
  const value =
    props.compProps && props.compProps.mode === "tags"
      ? labelName
      : props.chooseId;
  return list instanceof Array
    ? list.map(ele => (
        <Option
          value={props.dbType === "number" ? Number(ele[value]) : ele[value]}
          key={ele[props.chooseId]}
        >
          {ele[labelName]}
        </Option>
      ))
    : [];
};

export const handleMultipleSelectShow = (value, prop) => {
  let tempValue = value || [];
  if (value && typeof value === "string") {
    tempValue = JSON.parse(value);
  }
  const res = tempValue.length
    ? tempValue
        .map(ele => {
          const target =
            prop.chooseList.find(
              listItem =>
                String(listItem[prop.chooseId || "id"]) === String(ele)
            ) || {};
          return target[prop.chooseLabelKey || "name"] || "-";
        })
        .join("；")
    : "-";
  return res;
};
export const formartSubmitData = (formValue, editData, keyList, fieldProps) => {
  // console.log(formValue, editData, keyList, fieldProps);
  let formData = {};
  // const logData = {};
  keyList.forEach(ele => {
    const key = ele;
    const prop = fieldProps[key] || {};
    const { dataFormartType } = prop;
    const formKey = prop.formField;
    const value = formValue[formKey];
    // const editValue = editData[formKey];
    const funcObj = dataFormartTypeObj[dataFormartType] || {};
    const { submit } = funcObj;
    const submitValue = submit(value, prop);
    formData = { ...formData, ...submitValue };
    // if (diff && diff(value, editValue, prop)) {
    //   logData[formKey] = (logs && logs(value, editValue, prop)) || {
    //     name: label || ""
    //   };
    // }
  });
  return formData;
};
