/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import "./index.less";

class TitleCard extends Component {


  

  render() {
    const { title }=this.props;
    return (
      <div className='titleCard'>
        {title}
      </div>
    );
  }
}
export default TitleCard;
