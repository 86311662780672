/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Button, Form , Input , Row , Col ,message } from "antd";
import TitleCard from '../../components/TitleCard/index';
import queryList from "../../graphql/system";
import { loginOut ,getSession} from '../../utils/utils'
import axios from "axios";
import "./index.less";

const { Item } = Form;
const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 }
  }
};

class Passwords extends Component {
    constructor(props) {
      super(props);
      this.state={
        confirmDirty: false
      }
    }

  componentDidMount() {
    const { history }=this.props;
    getSession(history);
   }

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value&&confirmDirty) {
      form.validateFields(["password1"], { force: true });
    }
    if (value) {
      if (!/^[a-zA-Z0-9]+$/.test(value)) {
        return callback("输入英文或者数字组成密码");
      } 
      if (value.length < 6 || value.length > 16) {
        return callback("输入6-16位密码");
      }
    }
    return callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次密码输入不一致");
    } else {
      callback();
    }
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  loginOut = () => {
    const { history } = this.props;
    axios.post(`/graphql`, loginOut)
      .then(res => {
        if (res && res.data.data.logout.code === 200) {
          history.push('/user/login');
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  save = () => {
    const { form } = this.props;
    const that=this
    form.validateFields((err, value) => {
      if (!err) {
        delete value.Password1
        const obj = {
          query: queryList.updatePassword.query,
          variables: {...value}
        };
        axios
          .post(`/graphql`, obj)
          .then(res => {
           if(res&&res.data.data.updatePassword.code===200){
             message.success("密码修改成功,请重新登录")
             setTimeout(function(){
              that.loginOut()
          }, 2500);
           }else{
            message.error(res.data.data.updatePassword.msg)
           }
          })
          .catch(err => {
            message.error(err);
          });
      }
    });
  };

 

  render() {
    const { form:{getFieldDecorator} } = this.props;
    return (
      <div className="system_wraper">
        <div className="info_sys">
          <div style={{ display: "inline-flex" }}>
            <div className="sys_one">{""}</div>
            <div>
              系统配置 / <span className="sys_title">密码管理</span>
            </div>
          </div>
          <div className="sys_card">

            <div className="sys_cardTitle">
              <TitleCard title="密码管理" />
              {/* <div className="sys_carName">服务机构信息</div>
              <div className="sys_cardLine"></div> */}
            </div>
            
            <div className='passwordForm'>
              <Form {...formItemLayout}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Item label="原密码">
                      {getFieldDecorator("oldPassword", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            max: 30,
                            message: "请输入原密码"
                          }
                        ]
                      })(<Input type="password" />)}
                    </Item>
                    <Item label="设置新密码">
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "请输入新密码"
                          },
                          {
                            validator: this.validateToNextPassword
                          }
                        ]
                      })(<Input type="password" />)}
                    </Item>
                    <Item label="再次输入">
                      {getFieldDecorator("password1", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            max: 30,
                            message: "请确认新密码"
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
                    </Item>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Button
                    type="primary"
                    style={{ width: 100, marginTop: 24 }}
                    onClick={() => {
                      this.save();
                    }}
                  >
                    确认修改
              </Button>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Password = Form.create({})(Passwords);
export default Password;
